import {ReactNode} from 'react';

import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import ModalWindow from 'shared/ui/deprecated/ModalWindow/ModalWindow';

import * as S from './style';

interface ModalFactoryProps {
    open: boolean;
    title: string;
    description: string;
    children: ReactNode;
}

export function ModalFactory({open, title, description, children}: ModalFactoryProps) {
    return (
        <ModalWindow open={open}>
            <S.ModalTitle>{title}</S.ModalTitle>
            <S.ModalText>{description}</S.ModalText>
            <WrapperOffset offsetTop={16}>
                <WrapperFlex justifyContent="flex-end">{children}</WrapperFlex>
            </WrapperOffset>
        </ModalWindow>
    );
}
