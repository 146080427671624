import {deleteAttachment, postJobAttachment} from 'shared/services/files.service';

import {EActionType, FILE_ERROR_BASE, FILE_ERROR_MESSAGES} from './constants';
import {COMMA_SEPARATOR, EFileUploadStatus, SPACE_SEPARATOR} from '../constants';
import type {
    IDeleteFileThunk,
    IResetErrorThunk,
    ISetErrorThunk,
    IUploadFilesThunk,
    TFileDataList,
} from '../useFileUploader.types';
import {IAttachRegistrationDateThunk, IUpdateFileThunk} from '../useFileUploader.types';
import {EAttachmentType} from '../../../types/FileTypes';

export const updateFile = async (props: IUpdateFileThunk): Promise<void> => {
    const {oldFile, newFile, fileUploaderDispatch} = props;
    const {name} = newFile;
    const initialFile: TFileDataList = {
        [name]: {
            name,
            oldGuid: oldFile.name,
            status: EFileUploadStatus.Loading,
            type: EAttachmentType.Agreement,
            registration_at: oldFile.registration_at,
        },
    };
    fileUploaderDispatch({type: EActionType.ResetError});
    fileUploaderDispatch({type: EActionType.DeleteFile, payload: oldFile.name});
    fileUploaderDispatch({type: EActionType.AddFiles, payload: {files: initialFile}});

    try {
        const result = await postJobAttachment(newFile, oldFile.file_id, newFile.name);
        if (!result) return;

        fileUploaderDispatch({
            type: EActionType.ChangeFile,
            payload: {
                oldFile: oldFile,
                newFile: {
                    name,
                    guid: oldFile.guid,
                    file_id: result.data.ids[0].id,
                    history_id: result.data.ids[0].history_id,
                    type: result.data.ids[0].type,
                    oldGuid: oldFile.guid,
                    employee_id: 0,
                    created_at: 0,
                    //fileLink: enrichMediaUrlWithPrefix(url),
                    fileLink: 'sd',
                    status: EFileUploadStatus.Upload,
                    registration_at: result.data.ids[0].registration_at ?? null,
                },
            },
        });
    } catch {
        // В случае ошибки создаем ссылку на файл ( чтобы не хранить его в сторе ) и добавляем в хранилище со статусом 'error'
        const updatedLocalFileUrl = URL.createObjectURL(newFile);

        fileUploaderDispatch({
            type: EActionType.UpdateFile,
            payload: {
                name,
                status: EFileUploadStatus.Error,
                localFileUrl: updatedLocalFileUrl,
                type: EAttachmentType.Error,
                registration_at: oldFile.registration_at ?? null,
            },
        });
    }
};

export const uploadFiles = async (props: IUploadFilesThunk): Promise<void> => {
    const {files, fileUploaderDispatch} = props;
    const filesList = Array.isArray(files) ? files : Object.values(files);

    // Ассоциативный обьект добавляем в хранилище и устанавливаем каждому файлу статус 'loading'
    const initialFiles = [...filesList].reduce(
        (prevValue: TFileDataList, file: File): TFileDataList => {
            const {name} = file;

            return {
                ...prevValue,
                [name]: {name, status: EFileUploadStatus.Loading},
            } as TFileDataList;
        },
        {},
    );

    fileUploaderDispatch({type: EActionType.AddFiles, payload: {files: initialFiles}});

    // Загружаем файлы на сервер, и затем обогащаем их полученными guid.
    for (const file of filesList) {
        const {name, localFileUrl} = file;
        try {
            const requestFile = localFileUrl
                ? await fetch(localFileUrl).then((response) => response.blob())
                : file;

            const formData = new FormData();
            formData.append('fn', requestFile);
            const result = await postJobAttachment(requestFile, undefined, name);
            if (!result) return;

            fileUploaderDispatch({
                type: EActionType.UpdateFile,
                payload: {
                    name,
                    guid: result.data.ids[0].id,
                    file_id: result.data.ids[0].file_id,
                    history_id: result.data.ids[0].history_id,
                    type: result.data.ids[0].type,
                    registration_at: result.data.ids[0].registration_at ?? null,
                    employee_id: 0,
                    created_at: 0,
                    //fileLink: enrichMediaUrlWithPrefix(url),
                    fileLink: 'sd',
                    status: EFileUploadStatus.Upload,
                },
            });

            if (localFileUrl) {
                URL.revokeObjectURL(localFileUrl);
            }
        } catch {
            // В случае ошибки создаем ссылку на файл ( чтобы не хранить его в сторе ) и добавляем в хранилище со статусом 'error'
            const updatedLocalFileUrl = localFileUrl || URL.createObjectURL(file);
            fileUploaderDispatch({
                type: EActionType.UpdateFile,
                payload: {
                    name,
                    status: EFileUploadStatus.Error,
                    localFileUrl: updatedLocalFileUrl,
                    type: EAttachmentType.Error,
                    registration_at: null,
                },
            });
        }
    }
};

export const deleteFile = async (props: IDeleteFileThunk): Promise<void> => {
    const {fileUploaderDispatch, file, mode} = props;
    const {guid, name, file_id} = file;
    fileUploaderDispatch({type: EActionType.ResetError});
    try {
        if (guid) {
            fileUploaderDispatch({
                type: EActionType.UpdateFile,
                payload: {
                    name,
                    status: EFileUploadStatus.Loading,
                    type: EAttachmentType.Agreement,
                    registration_at: null,
                },
            });
            if (mode === 'creation') {
                await deleteAttachment(file_id ?? guid);
            }
        }
        fileUploaderDispatch({type: EActionType.DeleteFile, payload: name});
    } catch {
        fileUploaderDispatch({
            type: EActionType.UpdateFile,
            payload: {
                name,
                status: EFileUploadStatus.Error,
                type: EAttachmentType.Error,
                registration_at: null,
            },
        });
    }
};

export const attachRegistrationDate = (props: IAttachRegistrationDateThunk): void => {
    const {file, date, fileUploaderDispatch} = props;
    fileUploaderDispatch({type: EActionType.AttachDate, payload: {date, file}});
};

export const resetError = (props: IResetErrorThunk): void => {
    const {fileUploaderDispatch} = props;
    fileUploaderDispatch({type: EActionType.ResetError});
};

export const setError = (props: ISetErrorThunk): void => {
    const {fileUploaderDispatch, errorFactors} = props;
    const {
        isFilteredByAllowedFormats,
        isFilteredByMaxFileSize,
        isCroppedFilesCount,
        isEmptyRegistrationDate,
        isFileCountTooLow,
    } = errorFactors;

    const uploaderHasFileError =
        isFilteredByAllowedFormats ||
        isFilteredByMaxFileSize ||
        isCroppedFilesCount ||
        isEmptyRegistrationDate ||
        isFileCountTooLow;

    if (uploaderHasFileError) {
        const messagesSeparator = `${COMMA_SEPARATOR}${SPACE_SEPARATOR}`;

        const croppedFilesCountEM = [
            ...(isCroppedFilesCount ? [FILE_ERROR_MESSAGES.maxFilesCountExceeded] : []),
        ];
        const maxFileSizeEM = [
            ...(isFilteredByMaxFileSize ? [FILE_ERROR_MESSAGES.maxFileSize] : []),
        ];
        const allowedFormatsEM = [
            ...(isFilteredByAllowedFormats ? [FILE_ERROR_MESSAGES.allowedFormats] : []),
        ];
        const registrationDateEM = [
            ...(isEmptyRegistrationDate ? [FILE_ERROR_MESSAGES.hasNoRegistrationDate] : []),
        ];

        const minFileCountEM = [
            ...(isFileCountTooLow ? [FILE_ERROR_MESSAGES.fileCountBelowRequired] : []),
        ];
        const allErrorsPart = [
            ...croppedFilesCountEM,
            ...maxFileSizeEM,
            ...allowedFormatsEM,
            ...registrationDateEM,
            ...minFileCountEM,
        ].join(messagesSeparator);

        const errorMessage = `${FILE_ERROR_BASE}${allErrorsPart}`;
        fileUploaderDispatch({type: EActionType.SetError, payload: errorMessage});
    }
};
