import React, {useEffect} from 'react';
import {useStore} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {TrimTextArea} from 'Features/TrimTextArea';
import {
    $activeAccordion,
    $constructorPage,
    $createAdditionalAgreementData,
    $touchedConstructorForm,
    $validConstructor,
    checkSpecificationHasIncreasingCoefficientsWithNoAttachmentsOrComments,
    checkSpecificationHasManualCoefficientsWithNoAttachmentsOrComments,
    updateSpecAttachments,
    updateSpecDate,
} from 'processes/contructor';
import {TpiSpecification} from 'pages/VirSelect/types';
import {useFileUploader} from 'shared/ui/use-file-uploader';
import {TFileUploaderMode} from 'shared/ui/use-file-uploader/useFileUploader.types';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import Select, {SelectItem} from 'shared/ui/Select/Select';
import {JobCategory} from 'shared/types/jobsTypes';
import {deriveFileList, prepareTFileList} from 'shared/helpers/transformObject';
import {DOCUMENT_ALLOWED_FORMAT_LIST, MAX_ALLOWED_UPLOAD_FILE_SIZE} from 'shared/const/fileFormats';
import {
    ATTACHMENTS_TITLE,
    EXTRA_JOBS_CATEGORY_TITLE,
    EXTRA_JOBS_TITLE,
    ITEM_COMMENT_TITLE,
    JOB_DESCRIPTION_TITLE,
    NO_RESULT_TITLE,
    OBLIGATORY_FOR_INCREASING_COEFFICIENT_AND_AGREED_PRICE_HELPER,
    UNNECESSARY_TEXTAREA_LABEL,
} from 'shared/constants';

import * as S from './style';

interface SpecificationCommentBlockProps {
    currentVirId: string;
    currentWorkObjectId: string;
    workSpecificationId: string;
    currentSpecification: TpiSpecification;
}

export function SpecAttachmentAndComment({
    currentWorkObjectId,
    workSpecificationId,
    currentSpecification,
}: SpecificationCommentBlockProps) {
    const {works} = useStore($createAdditionalAgreementData);
    const isValid = useStore($validConstructor);
    const isTouched = useStore($touchedConstructorForm);
    const {jobCategoriesList} = useStore($constructorPage);

    const currentVirId = useStore($activeAccordion);
    let comment = '';
    const currentWorkSpecification =
        works[`${currentVirId}`]?.work_objects?.[`${currentWorkObjectId}`]?.work_specifications?.[
            `${workSpecificationId}`
        ];
    const commentArray = currentWorkSpecification?.work_specification_comments;

    if (Array.isArray(commentArray)) {
        comment = commentArray[0]?.comment ?? '';
    }

    const mode: TFileUploaderMode =
        workSpecificationId.startsWith('front') &&
        !workSpecificationId.startsWith('front -duplicated')
            ? 'creation'
            : 'additionalAgreementCreation';

    const jobCategoryDescription = currentWorkSpecification?.category_name ?? '';
    const selectedJobCategoryId = currentWorkSpecification?.work_category_id;
    const isAgreed = currentWorkSpecification?.is_agreed_price;
    const isCategory = currentWorkSpecification?.is_category;
    const hasInvalidIncreasingCoefficients =
        checkSpecificationHasIncreasingCoefficientsWithNoAttachmentsOrComments(
            currentSpecification,
        );
    const hasInvalidManualCoefficients =
        checkSpecificationHasManualCoefficientsWithNoAttachmentsOrComments(currentSpecification);
    const helperText =
        hasInvalidIncreasingCoefficients || isAgreed || hasInvalidManualCoefficients
            ? OBLIGATORY_FOR_INCREASING_COEFFICIENT_AND_AGREED_PRICE_HELPER
            : undefined;
    const selectedWorkCategory =
        selectedJobCategoryId && jobCategoriesList.length > 0
            ? [jobCategoriesList.find((item) => item.id === selectedJobCategoryId)]
            : [];

    const specInfo = {
        spec: currentSpecification,
        virId: currentVirId,
        workObjectId: currentWorkObjectId,
    };

    const [FileUploader, files, fileUploaderDispatch, isError] = useFileUploader({
        fileUploaderName: `work specification attachments_${currentWorkSpecification.id}`,
        maxFileSize: MAX_ALLOWED_UPLOAD_FILE_SIZE,
        acceptFormats: DOCUMENT_ALLOWED_FORMAT_LIST,
        maxFiles: 10,
        mode,
        initialFiles: prepareTFileList(currentSpecification.work_specification_attachments),
        externalError: isTouched && hasInvalidIncreasingCoefficients,
    });

    useEffect(() => {
        updateSpecAttachments({
            spec: currentSpecification,
            virId: currentVirId,
            workObjectId: currentWorkObjectId,
            valuesToUpdate: {
                ...currentSpecification,
                work_specification_attachments: deriveFileList(files),
            },
        });
    }, [files]);

    const handleChangeComment = (updatedComment: string) => {
        updateSpecDate({
            ...specInfo,
            valuesToUpdate: {
                work_specification_comments:
                    updatedComment === '' ? [] : [{comment: updatedComment}],
            },
        });
    };

    const handleChangeJobDescription = (updatedComment: string) => {
        updateSpecDate({
            ...specInfo,
            valuesToUpdate: {
                category_name: updatedComment,
            },
        });
    };

    const handleSetSelectedJobCategory = (categoryId: JobCategory[]) => {
        if (categoryId.length === 0) {
            return 1;
        }
        updateSpecDate({
            ...specInfo,
            valuesToUpdate: {
                work_category_id: categoryId[categoryId.length - 1].id,
            },
        });
    };

    return (
        <S.CommentItemBlockStyled>
            <WrapperFlex alignItems="flex-start" gap="48px">
                <WrapperFlex flexDirection="column" flex="0 0 567px" gap="24px" noOverflow>
                    {currentWorkSpecification?.is_category && (
                        <WrapperFlex flexDirection="column" gap="8px">
                            <Typography variant="h6">{EXTRA_JOBS_TITLE}</Typography>

                            <Select
                                value={selectedWorkCategory}
                                valueKey="id"
                                valueLabel="name"
                                title={EXTRA_JOBS_CATEGORY_TITLE}
                                handleEdit={handleSetSelectedJobCategory}
                                multiple
                                error={
                                    isCategory &&
                                    isTouched &&
                                    !isValid &&
                                    selectedWorkCategory.length === 0
                                }
                                fullWidth
                                fullWidthList
                            >
                                {jobCategoriesList.length > 0 ? (
                                    jobCategoriesList.map((item) => (
                                        <SelectItem key={item.id} value={item} multiple={false}>
                                            {item.name}
                                        </SelectItem>
                                    ))
                                ) : (
                                    <WrapperFlex justifyContent="center">
                                        <Typography variant="subtitle3">
                                            {NO_RESULT_TITLE}
                                        </Typography>
                                    </WrapperFlex>
                                )}
                            </Select>

                            <TrimTextArea
                                label={JOB_DESCRIPTION_TITLE}
                                value={jobCategoryDescription}
                                onChange={handleChangeJobDescription}
                                error={
                                    isCategory && isTouched && !isValid && !jobCategoryDescription
                                }
                                fullWidth
                            />
                        </WrapperFlex>
                    )}

                    <S.AttachmentUnitWrapper>
                        <Typography variant="h6">{ATTACHMENTS_TITLE}</Typography>
                        <FileUploader />
                    </S.AttachmentUnitWrapper>
                </WrapperFlex>
                <WrapperFlex flexDirection="column" flex="1 1 749px" gap="8px" minWidth="420px">
                    <Typography variant="h6">{ITEM_COMMENT_TITLE}</Typography>
                    <TrimTextArea
                        label={UNNECESSARY_TEXTAREA_LABEL}
                        helperText={helperText}
                        error={
                            (isAgreed ||
                                isCategory ||
                                hasInvalidIncreasingCoefficients ||
                                hasInvalidManualCoefficients) &&
                            isTouched &&
                            !isValid &&
                            !comment
                        }
                        value={comment}
                        onChange={handleChangeComment}
                        size="medium"
                        fullWidth
                    />
                </WrapperFlex>
            </WrapperFlex>
        </S.CommentItemBlockStyled>
    );
}
