import React from 'react';
import {useParams} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {DownloadableAttachment} from 'Features/DownloadableAttachment';
import {TrimTextArea} from 'Features/TrimTextArea';
import {Comment} from 'Entities/Comment';
import {CommonLayout} from 'shared/ui/CommonLayout';
import {DocumentCreationPage} from 'pages/DocumentCreationPage';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {EAttachmentType} from 'shared/types/FileTypes';
import {prepareTFileList} from 'shared/helpers/transformObject';
import {
    ATTACHMENTS_AND_COMMENTS_TITLE,
    COMMENT_TITLE,
    EMPTY_STRING,
    INSERT_COMMENT_HELPER,
    SAVE_BUTTON,
} from 'shared/constants';

import {DocumentViewInfoUnit} from './DocumentViewInfoUnit';
import {DocumentViewHeaderUnit} from './DocumentViewHeaderUnit';
import * as S from './style';
import {
    $rawDocumentStore,
    downloadAttachmentFile,
    RawDocumentPageGate,
    handleWorkflowBtn,
    sendComment,
    setComment,
    setEditMode,
    sendNewComment,
} from '../model';

export const DocumentViewPage = () => {
    const {documentId} = useParams<{documentId: string}>();
    useGate(RawDocumentPageGate, documentId);
    const {
        data,
        anyFileIsLoading,
        workflowButtonsStore: {workflowButtons, buttonIsLoading},
        isSendButtonHide,
        comment,
        commentError,
        isEditMode,
        isUserVK,
    } = useStore($rawDocumentStore);

    const handleDownloadAttachment = (fileId: string, fileName: string, type: EAttachmentType) => {
        downloadAttachmentFile({id: fileId, file_name: fileName, type: type});
    };

    const handleWorkflowButton = (index: number) => {
        if (!isUserVK) {
            handleWorkflowBtn(index);
        } else {
            workflowButtons &&
                sendComment({
                    index,
                    isPositive: workflowButtons.data.actions[index].is_positive ?? false,
                });
        }
    };

    return (
        <>
            {isEditMode === true ? (
                <DocumentCreationPage
                    mode="editing"
                    projId={data?.bs.id}
                    documentId={documentId}
                    initialFiles={prepareTFileList(data?.document_file)}
                    handleCloseEditing={setEditMode}
                    handleClickWorkflow={handleWorkflowButton}
                    subtype_document={data?.subtypes_document}
                    isWorkflowButtonClicked={buttonIsLoading}
                />
            ) : (
                <CommonLayout>
                    <WrapperOffset offset={32}>
                        <DocumentViewHeaderUnit handleWorkflowButton={handleWorkflowButton} />
                        <DocumentViewInfoUnit data={data} />
                        <Typography variant="h5">{ATTACHMENTS_AND_COMMENTS_TITLE}</Typography>
                        <S.AttachmentsBlock>
                            <S.FilesBlock>
                                {data &&
                                    data.document_file.map((item) => (
                                        <DownloadableAttachment
                                            key={item.id}
                                            item={item}
                                            id={item.id ?? item.file_id}
                                            title={item.file_name}
                                            type={item.type}
                                            handleDownloadAttachment={handleDownloadAttachment}
                                            disabled={anyFileIsLoading}
                                            isLoading={anyFileIsLoading}
                                        />
                                    ))}
                            </S.FilesBlock>
                            <S.CommentsBlock>
                                <S.AddCommentUnit>
                                    <TrimTextArea
                                        value={comment}
                                        label={COMMENT_TITLE}
                                        helperText={
                                            commentError ? INSERT_COMMENT_HELPER : EMPTY_STRING
                                        }
                                        onChange={setComment}
                                        error={commentError}
                                        fullWidth
                                    />
                                    {!isSendButtonHide && (
                                        <S.StyledButton
                                            size="medium"
                                            onClick={() => sendNewComment()}
                                        >
                                            {SAVE_BUTTON}
                                        </S.StyledButton>
                                    )}
                                </S.AddCommentUnit>

                                {data &&
                                    data.document_comment.map((item) => (
                                        <Comment
                                            key={item.id ?? item.created_at}
                                            id={item.id}
                                            creator={item.creator}
                                            created_at={item.created_at}
                                            comment={item.comment}
                                        />
                                    ))}
                            </S.CommentsBlock>
                        </S.AttachmentsBlock>
                    </WrapperOffset>
                </CommonLayout>
            )}
        </>
    );
};
