import {cloneDeep} from 'lodash';
import {Option} from '@beeline/design-system-react';

import {
    ESpecificationAction,
    IAdaptedCompletedWorksSpecification,
    ICompletedWorksAgreement,
    ICompletedWorksDocument,
    ICompletedWorkSpecification,
    ICompletedWorksSpecification,
    TAdaptedCompletedWorksAgreement,
    TAdaptedCompletedWorksObject,
    TAdaptedCompletedWorksSheet,
    TBackendAdaptedCompletedWorksSheet,
    TBackendCompletedWorksAgreement,
    TCompletedWorkSpecificationMode,
    TCompletedWorksSpecAction,
} from 'shared/types/completedWorksTypes';
import {TMeta} from 'shared/types/commonTypes';
import {EWorkObjectTypes} from 'shared/types/additionalAgreementsTypes';
import {calcTotalSum, calcVatSum} from 'shared/helpers/calculators';
import {formatNumber} from 'shared/helpers/formatHelper';
import {EAttachmentType, TAttachmentFile} from 'shared/types/FileTypes';

import {IWorksheetOption} from './stage1';
import {attachmentFetched} from './stage2';

export interface IObjectLocation {
    worksheetId: string;
    objectId: string;
}

export interface ISpecificationLocation extends IObjectLocation {
    specificationId: string;
}

export interface ISelectedSpecification extends ISpecificationLocation {
    checked: boolean;
}

export interface IAppliedSpecification extends ISpecificationLocation {
    action: TCompletedWorksSpecAction;
}

export interface ISpecificationComment extends ISpecificationLocation {
    comment: string;
}

export interface ISpecificationAttachments extends ISpecificationLocation {
    attachments: TAttachmentFile[];
}

export interface ISpecificationQuantity extends ISpecificationLocation {
    quantity: string;
}

export type TMode = 'creation' | 'editing' | 'drafting';

// преобразование начального ВВР объекта для второй страницы конструктора
export const adaptCompletedWorksAgreement = (
    initialAgreement: ICompletedWorksAgreement,
    selectedWorksheetList: IWorksheetOption[],
): TAdaptedCompletedWorksAgreement => {
    const worksheetIdList = selectedWorksheetList
        .filter((item) => item.id !== 'all' && item.offset === undefined)
        .map((item) => item.id);
    const objectIdList = selectedWorksheetList
        .filter((item) => item.id !== 'all' && item.offset !== undefined)
        .map((item) => item.id);

    const works = initialAgreement.works.map((work) => {
        const workObjects = work.work_objects.map((object) => {
            const workSpecifications = object.work_specifications.ds.map(
                (specification) =>
                    ({
                        ...specification,
                        checked: false,
                        mode: 'available',
                        quantity: specification.quantity_max.toString(),
                        result: null,
                        completed_work_specification_comment: null,
                    } as IAdaptedCompletedWorksSpecification),
            );
            const finishedWorkSpecifications = object.work_specifications.vvr_other.map(
                (specification) =>
                    ({
                        ...specification,
                        checked: false,
                        quantity: specification.quantity_completed.toString(),
                        mode: 'closed',
                        completed_work_specification_comment:
                            specification.work_specification_comments.find(
                                (comment) => !!comment.id,
                            ) ?? '',
                    } as IAdaptedCompletedWorksSpecification),
            );
            return {
                ...object,
                work_specifications: workSpecifications.filter((spec) => spec.quantity_max > 0),
                completed_work_specifications: [],
                finished_work_specifications: finishedWorkSpecifications,
            } as TAdaptedCompletedWorksObject;
        });
        return {
            ...work,
            work_objects: workObjects,
            checked: false,
            checkboxType: 'checkbox',
        } as TAdaptedCompletedWorksSheet;
    });

    const filteredWorks = works
        .filter((worksheet) => worksheetIdList.includes(worksheet.id))
        .map((worksheet) => ({
            ...worksheet,
            work_objects: worksheet.work_objects.filter((object) =>
                objectIdList.includes(object.id),
            ),
        }));

    const result: TAdaptedCompletedWorksAgreement = {
        ...initialAgreement,
        works: filteredWorks,
        completed_work_comment: null,
        completed_work_attachments: [],
        ds_attachments: initialAgreement.ds_attachments,
        meta: 'save_draft',
    };

    return result;
};

export const toggleSpecificationCheckbox = (
    agreement: TAdaptedCompletedWorksAgreement,
    {worksheetId, objectId, specificationId}: ISpecificationLocation,
    checked: boolean,
): TAdaptedCompletedWorksAgreement => {
    const result = {...agreement};
    const specification = result.works
        .find((work) => work.id === worksheetId)
        ?.work_objects.find((object) => object.id === objectId)
        ?.work_specifications.find((spec) => spec.id === specificationId);
    if (!!specification) {
        specification.checked = checked;
    }

    result.works = result.works.map((work) => handleChangeCommonCheckbox(work));

    return result;
};

export const handleChangeCommonCheckbox = (
    worksheet: TAdaptedCompletedWorksSheet,
): TAdaptedCompletedWorksSheet => {
    const result = {...worksheet};
    const allChecked = worksheet.work_objects.every((object) =>
        object.work_specifications.every((spec) => spec.checked),
    );

    if (allChecked) {
        result.checkboxType = 'checkbox';
        result.checked = true;
        return result;
    }

    const someChecked = worksheet.work_objects.some((object) =>
        object.work_specifications.some((spec) => spec.checked),
    );

    if (someChecked) {
        result.checkboxType = 'indeterminate';
        result.checked = true;
        return result;
    }
    const noneChecked = worksheet.work_objects.every((object) =>
        object.work_specifications.every((spec) => !spec.checked),
    );

    if (noneChecked) {
        result.checkboxType = 'checkbox';
        result.checked = false;
        return result;
    }

    return result;
};

export const toggleWorksheetCheckbox = (
    agreement: TAdaptedCompletedWorksAgreement,
    worksheetId: string,
    checked: boolean,
): TAdaptedCompletedWorksAgreement => {
    const result = {...agreement};
    result.works = result.works.map((work) => {
        if (work.id === worksheetId) {
            return {
                ...work,
                checkboxType: 'checkbox',
                checked: checked,
                work_objects: work.work_objects.map((object) => ({
                    ...object,
                    work_specifications: object.work_specifications.map((spec) => {
                        if (spec.mode !== 'available' || spec.quantity_max === 0) return spec;
                        return {
                            ...spec,
                            checked: checked,
                        };
                    }),
                })),
            };
        }
        return work;
    });

    return result;
};

// включение пункта в ВВР
export const addSingleSpecification = (
    agreement: TAdaptedCompletedWorksAgreement,
    location: ISpecificationLocation,
    vatRate: number | null,
): TAdaptedCompletedWorksAgreement => {
    const {worksheetId, objectId, specificationId} = location;
    const result = {...agreement};
    const worksheetReference = result.works.find((worksheet) => worksheet.id === worksheetId);
    const objectReference = worksheetReference?.work_objects.find(
        (object) => object.id === objectId,
    );
    const requiredSpecification = objectReference?.work_specifications.find(
        (spec) => spec.id === specificationId,
    );
    if (requiredSpecification) {
        requiredSpecification.checked = false;
        requiredSpecification.mode = 'current';

        const specCopy = actualizeSums(requiredSpecification, vatRate);
        specCopy.result = ESpecificationAction.DONE;
        objectReference?.completed_work_specifications.push(specCopy);
    }
    return result;
};

export const addAllSelectedSpecifications = (
    agreement: TAdaptedCompletedWorksAgreement,
    worksheetId: string,
): TAdaptedCompletedWorksAgreement => {
    const result = {...agreement};

    result.works = result.works.map((work) => {
        if (work.id === worksheetId) {
            return {
                ...work,
                checkboxType: 'checkbox',
                checked: false,
                work_objects: work.work_objects.map((object) => ({
                    ...object,
                    work_specifications: object.work_specifications.map((spec) => {
                        if (!spec.checked) {
                            return spec;
                        }
                        return {
                            ...spec,
                            checked: false,
                            mode: 'current',
                            result: ESpecificationAction.DONE,
                        };
                    }),
                    completed_work_specifications: [
                        ...object.completed_work_specifications,
                        ...object.work_specifications
                            .filter((spec) => spec.checked)
                            .map((spec) => {
                                return {
                                    ...spec,
                                    checked: false,
                                    mode: 'current',
                                    result: 1,
                                };
                            }),
                    ] as IAdaptedCompletedWorksSpecification[],
                })),
            };
        }
        return work;
    });

    return result;
};

export const changeSpecificationAction = (
    agreement: TAdaptedCompletedWorksAgreement,
    location: IAppliedSpecification,
    vatRate: number | null,
) => {
    const {worksheetId, objectId, specificationId, action} = location;
    const result = {...agreement};
    const requiredSpecification = result.works
        .find((worksheet) => worksheet.id === worksheetId)
        ?.work_objects.find((object) => object.id === objectId)
        ?.completed_work_specifications.find((spec) => spec.id === specificationId);

    if (!!requiredSpecification) {
        requiredSpecification.result = action;
        switch (action) {
            case ESpecificationAction.DONE: {
                const {quantity, price, coefficient} = requiredSpecification;
                const {sum, sum_tax, total_sum} = calculateSums({
                    quantity,
                    price,
                    coefficient,
                    vatRate,
                });

                requiredSpecification.sum = sum;
                requiredSpecification.sum_tax = sum_tax;
                requiredSpecification.total_sum = total_sum;

                break;
            }
            case ESpecificationAction.NOTHING: {
                requiredSpecification.sum = '0';
                requiredSpecification.sum_tax = '0';
                requiredSpecification.total_sum = '0';
            }
        }
    }
    return result;
};

export const changeSpecificationQuantity = (
    agreement: TAdaptedCompletedWorksAgreement,
    location: ISpecificationQuantity,
    vatRate: number | null,
): TAdaptedCompletedWorksAgreement => {
    const {quantity} = location;
    const result = cloneDeep(agreement);
    const requiredSpecification = getCompletedSpecification(result, location);
    if (requiredSpecification) {
        requiredSpecification.quantity = quantity;

        const {price, coefficient} = requiredSpecification;
        switch (requiredSpecification.result) {
            case ESpecificationAction.DONE: {
                const {sum, sum_tax, total_sum} = calculateSums({
                    quantity,
                    price,
                    coefficient,
                    vatRate,
                });
                requiredSpecification.sum = sum;
                requiredSpecification.sum_tax = sum_tax;
                requiredSpecification.total_sum = total_sum;
                break;
            }
            case ESpecificationAction.NOTHING: {
                requiredSpecification.sum = '0';
                requiredSpecification.sum_tax = '0';
                requiredSpecification.total_sum = '0';
                break;
            }
        }
    }

    return result;
};

// удаление добавленной спецификации
export const removeCheckedSpecification = (
    agreement: TAdaptedCompletedWorksAgreement,
    location: ISpecificationLocation,
): TAdaptedCompletedWorksAgreement => {
    const result = cloneDeep(agreement);
    const requiredObject = getWorkObject(result, location);
    const requiredSpecification = getCompletedSpecification(result, location);
    if (!!requiredSpecification && !!requiredObject) {
        const masterPosition = requiredSpecification.master_position;

        requiredObject.completed_work_specifications =
            requiredObject.completed_work_specifications.filter(
                (spec) => spec.master_position !== masterPosition,
            );
        const workSpecification = requiredObject.work_specifications.find(
            (spec) => spec.master_position === masterPosition,
        );
        if (!!workSpecification) {
            workSpecification.checked = false;
            workSpecification.mode = 'available';
            workSpecification.result = null;
            workSpecification.completed_work_specification_comment = null;
        }
    }
    return result;
};

export const setCommentToSpecification = (
    agreement: TAdaptedCompletedWorksAgreement,
    location: ISpecificationComment,
): TAdaptedCompletedWorksAgreement => {
    const {comment} = location;
    const result = {...agreement};

    const requiredSpecification = getCompletedSpecification(result, location);
    if (!!requiredSpecification) {
        requiredSpecification.completed_work_specification_comment =
            comment !== '' ? comment : null;
    }
    return result;
};

export const setAttachmentsToSpecification = (
    agreement: TAdaptedCompletedWorksAgreement,
    location: ISpecificationAttachments,
): TAdaptedCompletedWorksAgreement => {
    const {attachments} = location;
    const result = {...agreement};

    const requiredSpecification = getCompletedSpecification(result, location);
    if (!!requiredSpecification) {
        requiredSpecification.completed_work_specification_attachments = attachments;
    }
    return result;
};

export const setCommonComment = (
    agreement: TAdaptedCompletedWorksAgreement,
    comment: string,
): TAdaptedCompletedWorksAgreement => {
    const result = {...agreement};
    result.completed_work_comment = comment;
    return result;
};

export const setCommonAttachments = (
    agreement: TAdaptedCompletedWorksAgreement,
    attachmentList: TAttachmentFile[],
): TAdaptedCompletedWorksAgreement => {
    const result = {...agreement};
    result.completed_work_attachments = attachmentList;
    return result;
};

const getWorkObject = (
    agreement: TAdaptedCompletedWorksAgreement,
    location: IObjectLocation,
): TAdaptedCompletedWorksObject | undefined => {
    const {worksheetId, objectId} = location;
    return agreement.works
        .find((worksheet) => worksheet.id === worksheetId)
        ?.work_objects.find((object) => object.id === objectId);
};

const getCompletedSpecification = (
    agreement: TAdaptedCompletedWorksAgreement,
    location: ISpecificationLocation,
): IAdaptedCompletedWorksSpecification | undefined => {
    const {worksheetId, objectId, specificationId} = location;
    return agreement.works
        .find((worksheet) => worksheet.id === worksheetId)
        ?.work_objects.find((object) => object.id === objectId)
        ?.completed_work_specifications.find((spec) => spec.id === specificationId);
};

// преобразование ВВР объекта для отравки на бэк
export const adaptAgreementForBackend = (
    agreement: TAdaptedCompletedWorksAgreement,
    vatRate: number | null,
    meta: TMeta,
): TBackendCompletedWorksAgreement => {
    const tempAgreement: TAdaptedCompletedWorksAgreement = {...agreement};
    const result: TBackendCompletedWorksAgreement = {
        agreement_id: agreement.id,
        contract_id: agreement.contract.id,
        project_id: agreement.project.id,
        works: [],
        meta: meta,
        completed_work_comment: agreement.completed_work_comment,
        completed_work_attachments: agreement.completed_work_attachments,
        sum: 0,
        sum_tax: 0,
        total_sum: 0,
    };

    result.works = tempAgreement.works.map((worksheet) => ({
        id: worksheet.id,
        work_objects: worksheet.work_objects.map((object) => ({
            id: object.id,
            completed_work_specifications: object.completed_work_specifications.map(
                (spec, index) => ({
                    work_specification: spec.id,
                    result: spec.result,
                    sum: spec.sum,
                    sum_tax: calcVatSum(+spec.sum, vatRate).toString(),
                    total_sum: calcTotalSum(+spec.sum, vatRate).toString(),
                    price: spec.price,
                    rate: vatRate ?? 0,
                    quantity: spec.quantity,
                    master_position: spec.master_position,
                    completed_work_specification_comment: spec.completed_work_specification_comment,
                    completed_work_specification_attachments:
                        spec.completed_work_specification_attachments,
                    ordinal_number: index + 1,
                }),
            ),
        })),
    }));

    let sum = 0;

    result.works.forEach((worksheet) =>
        worksheet.work_objects.forEach((object) =>
            object.completed_work_specifications.forEach((spec) => {
                sum = sum + +spec.sum;
            }),
        ),
    );

    result.sum = formatNumber(sum);
    result.sum_tax = calcVatSum(sum, vatRate);
    result.total_sum = calcTotalSum(result.sum, vatRate);

    return result;
};

// преобразование ВВР из карточки в конструктор (редактирование)
export const adaptCompletedWorksViewDataToEditingConstructor = (
    document: ICompletedWorksDocument,
    vatRate: number | null,
): TAdaptedCompletedWorksAgreement => {
    const result: TAdaptedCompletedWorksAgreement = {
        agreement: document.agreement,
        id: document.agreement.id,
        completed_works_id: document.id,
        number: document.agreement.agreement_number,
        addendum_number: null,
        bs_name: document.project.bs_name,
        project: {
            id: document.project.id,
            project_ext_id: document.project.project_number,
        },
        contract: {
            id: document.contract_id,
            number: document.contract_name,
        },
        ds_attachments: document.ds_attachments,
        works: document.works.map((sheet, index) => ({
            id: sheet.id,
            appendix_number: index,
            work_objects: sheet.work_objects.map((object) => {
                const completedSpecifications = object.specifications.vvr_current.map((spec) =>
                    mapSpecificationForEditing(spec, 'current', vatRate),
                );
                const availableSpecifications = object.specifications.ds.map((spec) =>
                    mapSpecificationForEditing(spec, 'available', vatRate),
                );
                completedSpecifications.forEach((completedSpec) => {
                    const spec = availableSpecifications.find(
                        (availableSpec) =>
                            availableSpec.master_position === completedSpec.master_position,
                    );
                    if (!!spec) {
                        spec.quantity_max = spec.quantity_max + completedSpec.quantity_completed;
                        spec.quantity = spec.quantity_max.toString();
                        spec.quantity_completed =
                            spec.quantity_completed - completedSpec.quantity_completed;
                    } else {
                        const actualizedSpec = actualizeSums(completedSpec, vatRate);
                        availableSpecifications.push(actualizedSpec);
                    }
                });

                availableSpecifications.forEach((spec) => {
                    if (
                        completedSpecifications.some(
                            (completedSpec) =>
                                completedSpec.master_position === spec.master_position,
                        )
                    )
                        spec.mode = 'current';
                });
                const closedSpecifications = object.specifications.vvr_other.map((spec) =>
                    mapSpecificationForEditing(spec, 'closed', vatRate),
                );

                return {
                    id: object.id,
                    gfk: object.gfk,
                    name: object.name,
                    object_type: object.object_type,
                    is_half_set: false, //
                    closed: false,
                    lav_gfk: undefined, //
                    completed_work_specifications: completedSpecifications,
                    work_specifications: availableSpecifications,
                    finished_work_specifications: closedSpecifications,
                };
            }),
            checked: false,
            checkboxType: 'checkbox',
        })),
        completed_work_attachments: document.completed_work_attachments,
        completed_work_comment: null,
        meta: 'save_draft',
    };
    return result;
};

export const mapSpecificationForEditing = (
    spec: ICompletedWorkSpecification,
    mode: TCompletedWorkSpecificationMode,
    vatRate: number | null,
): IAdaptedCompletedWorksSpecification => {
    const getQuantity = () => {
        switch (mode) {
            case 'current':
                return spec.quantity_completed.toString();
            case 'available':
                return spec.quantity_max.toString();
            case 'closed':
                return spec.quantity_completed.toString();
        }
    };
    const maxQuantity =
        mode === 'current' ? spec.quantity_max + spec.quantity_completed : spec.quantity_max;
    const result = (spec.result ?? 3) > 2 || (spec.result ?? 0) < 1 ? null : spec.result;
    const comment =
        mode === 'current'
            ? spec.completed_work_specification_comments.filter((item) => !!item.id)?.pop()
                  ?.comment ?? null
            : null;
    const {sum, sum_tax, total_sum} = calculateSums({
        quantity: getQuantity(),
        price: spec.price,
        coefficient: spec.coefficient,
        vatRate,
    });

    return {
        id: spec.id,
        ordinal_number: spec.ordinal_number ?? 0,
        number: spec.number,
        name: spec.name,
        note: spec.note,
        unit: spec.unit,
        quantity: getQuantity(),
        price: spec.price,
        is_prepayment: spec.is_prepayment,
        is_agreed_price: spec.is_agreed_price,
        is_ams: spec.is_ams,
        is_foundation: spec.is_foundation,
        is_quantity_editable: spec.is_quantity_editable,
        link_document_id: spec.link_document_id,
        accuracy: spec.accuracy,
        coefficient: spec.coefficient,
        coefficient_ams: spec.coefficient_ams,
        coefficient_foundation: spec.coefficient_foundation,
        coefficient_remote_territory: spec.coefficient_remote_territory,
        sum: result === ESpecificationAction.NOTHING ? spec.sum : sum,
        sum_tax: ESpecificationAction.NOTHING ? spec.sum_tax : sum_tax,
        total_sum: ESpecificationAction.NOTHING ? spec.total_sum : total_sum,
        work_coefficients: spec.work_coefficients,
        work_detail: spec.work_detail,
        tpi_specification: spec.tpi_specification,
        is_category: spec.is_category,
        parent_name: spec.parent_name,
        category_name: spec.category_name,
        master_position: spec.master_position,
        quantity_completed: spec.quantity_completed,
        quantity_full: spec.quantity_full,
        quantity_max: maxQuantity,
        checked: false,
        mode: mode,
        rate: vatRate ?? 0,
        result: result,
        completed_work_specification_comment: comment,
        completed_work_specification_comments: spec.completed_work_specification_comments,
        completed_work_specification_attachments:
            spec.completed_work_specification_attachments ?? [],
        vvr_number: spec.vvr_number,
        work_specification_attachments: spec.work_specification_attachments,
        work_specification_comments: spec.work_specification_comments,
    };
};

export const getMockBackendCompletedWorksAgreement = (): TBackendCompletedWorksAgreement => ({
    agreement_id: '',
    project_id: '',
    contract_id: '',
    works: [] as TBackendAdaptedCompletedWorksSheet[],
    meta: 'save_draft',
    completed_work_comment: '',
    completed_work_attachments: [],
    sum: 0,
    sum_tax: 0,
    total_sum: 0,
});

export const checkAgreementForErrors = (
    agreement: TAdaptedCompletedWorksAgreement,
): string | null => {
    const completedWorksWithoutSomeResults = !agreement.works.every((work) =>
        work.work_objects.every((object) => {
            if (object.completed_work_specifications.length > 0) {
                return agreement.works.every((work) =>
                    work.work_objects.every((object) =>
                        object.completed_work_specifications.every((spec) => spec.result ?? -1 > 0),
                    ),
                );
            }
            return true;
        }),
    );
    const completedWorksWithoutAddedSpecs = agreement.works.every((work) =>
        work.work_objects.every((object) => object.completed_work_specifications.length === 0),
    );

    if (completedWorksWithoutAddedSpecs || completedWorksWithoutSomeResults) {
        return 'Для того, чтобы отправить ВВР на согласование, добавьте работы в ведомость и установите для каждой из них результат.';
    }
    return null;
};

// метод формирования списка ведомостей выбранного ДС
export const convertAgreementToSelectList = (
    rawAgreementList: ICompletedWorksAgreement[],
    selectedAgreementList: Option<string>[],
): IWorksheetOption[] => {
    const result: IWorksheetOption[] = [];
    if (selectedAgreementList.length === 0) return result;
    result.push({
        id: 'all',
        value: 'Выбрать все',
        checked: true,
        disabled: false,
    });
    rawAgreementList
        .find((item) => item.id === selectedAgreementList[0].id)
        ?.works.forEach((worksheet, index) => {
            const allowed = worksheet.work_objects.some((object) => !object.closed);
            result.push({
                id: worksheet.id,
                value: `Ведомость ${index + 1}`,
                checked: allowed,
                disabled: !allowed,
            });
            worksheet.work_objects.forEach((object) => {
                const value = `${object.object_type === EWorkObjectTypes.BS ? `БС ` : ''}${
                    object.gfk
                }${object.object_type === EWorkObjectTypes.MAIN_PART ? ' (основная)' : ''}${
                    object.object_type === EWorkObjectTypes.RESPONSE_PART ? ' (ответная)' : ''
                }`;
                result.push({
                    id: object.id,
                    value: value,
                    checked: !object.closed,
                    disabled: object.closed,
                    offset: worksheet.id,
                });
            });
        });
    return result;
};

// преобразовывает данные с бэка в список ведомостей и объектов для компонента Select
export const changeSelectedOptions = (
    initialList: IWorksheetOption[],
    prevList: IWorksheetOption[],
    clickedItem: IWorksheetOption,
): IWorksheetOption[] => {
    // "выбрать все"
    if (clickedItem.id === 'all') {
        return prevList.find((item) => item.id === 'all') ? [] : initialList;
    }
    // если выделить ведомость, выделятся все объекты, прикрепленные к ней
    // если убрать выделение ведомости, снимется выделение с нее и всех связанных объектов
    else if (!clickedItem.offset) {
        const result = prevList.find((prevItem) => prevItem.id === clickedItem.id)
            ? prevList
                  .filter(
                      (prevItem) =>
                          (prevItem.id !== clickedItem.id && prevItem.offset === undefined) ||
                          (prevItem.id !== clickedItem.id && prevItem.offset !== clickedItem.id),
                  )
                  .filter((item) => item.id !== 'all')
            : [
                  ...prevList,
                  ...initialList.filter((initialItem) => initialItem.offset === clickedItem.id),
                  clickedItem,
              ];
        if (initialList.length - result.length === 1 && !result.some((item) => item.id === 'all')) {
            return [...result, ...initialList.filter((item) => item.id === 'all')];
        }

        return result;
    }

    // если выделить объект, выделится и ведомость объекта,
    // если убрать выделение обьекта,
    // и у ведомости не будет других выделенных объектов, то выделение ведомости снимется
    if (prevList.find((prevItem) => prevItem.id === clickedItem.id)) {
        const result = prevList
            .filter((prevItem) => prevItem.id !== clickedItem.id)
            .filter((prevItem) => prevItem.id !== 'all');
        return result.some((resultItem) => resultItem.offset === clickedItem.offset)
            ? result
            : result.filter((resultItem) => resultItem.id !== clickedItem.offset);
    } else {
        const result = [
            ...new Set([
                ...prevList,
                clickedItem,
                ...initialList.filter((initialItem) => initialItem.id === clickedItem.offset),
            ]),
        ];

        if (initialList.length - result.length === 1 && !result.some((item) => item.id === 'all')) {
            return [...result, ...initialList.filter((item) => item.id === 'all')];
        }
        return result;
    }
};

export const handleDownloadAttachment = (fileId: string, fileName: string, type: EAttachmentType) =>
    attachmentFetched({
        fileId,
        fileName,
        type,
    });

export const calculateSums = ({
    quantity,
    price,
    coefficient,
    vatRate,
}: Pick<ICompletedWorksSpecification, 'quantity' | 'price' | 'coefficient'> & {
    vatRate: number | null;
}): Pick<ICompletedWorksSpecification, 'sum' | 'sum_tax' | 'total_sum'> => {
    const newSum = formatNumber(+quantity * +price * +coefficient, 2);
    const newTaxSum = calcVatSum(newSum, vatRate);
    const newTotalSum = calcTotalSum(newSum, vatRate);

    return {
        sum: newSum.toString(),
        sum_tax: newTaxSum.toString(),
        total_sum: newTotalSum.toString(),
    };
};

export const actualizeSums = (
    specification: IAdaptedCompletedWorksSpecification,
    vatRate: number | null,
): IAdaptedCompletedWorksSpecification => {
    const result = cloneDeep(specification);
    const {quantity, price, coefficient} = result;
    const {sum, sum_tax, total_sum} = calculateSums({quantity, price, coefficient, vatRate});
    result.sum = sum;
    result.sum_tax = sum_tax;
    result.total_sum = total_sum;
    return result;
};

export const calcCompletion = (
    data: TAdaptedCompletedWorksAgreement | null,
    property: string,
): number => {
    if (!data) return 0;
    const {
        agreement: {sum},
    } = data;

    const currentSum = data.works.reduce((prevSum, curr) => {
        const currentWorksSum = curr.work_objects.reduce((prevWorkSpecSum, currObject) => {
            const workSpecSum = currObject[property].reduce((prevSpec, currSpec) => {
                return prevSpec + +currSpec.sum;
            }, 0);
            return prevWorkSpecSum + workSpecSum;
        }, 0);
        return prevSum + currentWorksSum;
    }, 0);
    return formatNumber((currentSum / +sum) * 100, 0);
};
