import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useGate, useUnit} from 'effector-react';
import {Select, Typography} from '@beeline/design-system-react';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {ActionButtonsUnit} from 'Features/ActionButtonsUnit';
import {VatErrorWidget} from 'widgets/VatErrorWidget';
import {$vatRate} from 'shared/model/user';
import {TAX_SETTINGS_ARE_NOT_FOUND_TITLE} from 'shared/constants';

import * as S from './style';
import {changeStageButtonClicked} from '../../model';
import {
    $stage1Store,
    agreementSelected,
    CompletedWorksStage1Gate,
    IWorksheetOption,
    worksheetOptionSelected,
    worksheetSelected,
} from '../../model/stage1';

interface Stage1Props {
    projectId?: string;
}

export const Stage1 = ({projectId}: Stage1Props) => {
    useGate(CompletedWorksStage1Gate, projectId);
    const navigate = useNavigate();
    const [
        {
            agreementList,
            worksheetList,
            selectedAgreementList,
            selectedWorksheetList,
            agreementListDisabled,
            worksheetListDisabled,
            positiveButtonDisabled,
        },
        vatRate,
    ] = useUnit([$stage1Store, $vatRate]);

    return (
        <S.Wrapper>
            {vatRate === null ? (
                <>
                    <VatErrorWidget
                        errorText={TAX_SETTINGS_ARE_NOT_FOUND_TITLE}
                        onClose={() => navigationInvoked({to: -1})}
                        hasLink
                    />
                </>
            ) : (
                <>
                    <Typography variant="h4">
                        Выберите доп. соглашение и ведомости, для которых хотите создать ВВР
                    </Typography>
                    <Select
                        label="Доп. соглашение"
                        onChange={agreementSelected}
                        values={selectedAgreementList}
                        options={agreementList}
                        loading={agreementListDisabled}
                        fullWidth
                    />
                    <Select
                        label="ВИР"
                        onChange={worksheetSelected}
                        values={selectedWorksheetList}
                        makeOption={makeSelectOption}
                        onManualClickOption={worksheetOptionSelected}
                        options={worksheetList}
                        disabled={worksheetListDisabled}
                        multiple
                        fullWidth
                    />
                    <ActionButtonsUnit
                        negativeBtnLabel="Отменить"
                        positiveBtnLabel="Продолжить"
                        handleNegativeBtn={() => navigate(-1)}
                        handlePositiveBtn={() => changeStageButtonClicked(2)}
                        disabledPositiveBtn={positiveButtonDisabled}
                        alignSelf="flex-end"
                    />
                </>
            )}
        </S.Wrapper>
    );
};

const makeSelectOption = (option: IWorksheetOption) => {
    return (
        <S.OffsetTypography variant="body2" $offset={!!option.offset}>
            {option.value}
        </S.OffsetTypography>
    );
};
