import {TableData} from '@beeline/design-system-react';
import {sizeSpacingX2} from '@beeline/design-tokens/js/tokens';

import {PopoverWithCopyButton} from 'Features/PopoverWithCopyButton';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

interface NameCellProps {
    note?: string;
    name: string;
    withoutWidth?: boolean;
    lineCount?: number;
    className?: string;
}

export const NameCell = (props: NameCellProps) => {
    const {note, name, withoutWidth, lineCount = 3, className} = props;

    const width = withoutWidth ? undefined : 'calc(100% - 927px)!important';
    return (
        <TableData width={width}>
            <WrapperFlex justifyContent="flex-start" alignItems="center" gap={sizeSpacingX2}>
                {note && <PopoverWithCopyButton text={note} className={className} />}
                <TooltipedSpan title={name} lineCount={lineCount} className={className}>
                    {name}
                </TooltipedSpan>
            </WrapperFlex>
        </TableData>
    );
};
