import React from 'react';
import {Link} from 'react-router-dom';
import {Banner} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {WORK_EMAIL} from 'shared/const/url';
import {BACK_BUTTON} from 'shared/constants';

interface VatNotFoundBannerProps {
    errorText: string;
    hasLink?: boolean;
    onClose: () => void;
}

export const VatNotFoundBanner = ({errorText, hasLink, onClose}: VatNotFoundBannerProps) => {
    return (
        <Banner
            title={
                <p>
                    {`${errorText} `}
                    {hasLink && <Link to={`mailto:${WORK_EMAIL}`}>{WORK_EMAIL}</Link>}
                </p>
            }
            color="error"
            iconName={Icons.InfoCircled}
            actions={[{label: BACK_BUTTON, onClick: onClose}]}
        />
    );
};
