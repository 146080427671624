import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {or} from 'patronum';

import {snackbarNotificationChanged} from 'Entities/SnackbarNotification/model';
import {completedWorksDocumentEdited} from 'processes/CompletedWorksProcess/model/stage2';
import {
    fetchCompletedWorksFile,
    fetchCompletedWorksSheet,
    postApproveCompletedWorksSpecificationItem,
    postCompletedWorksComment,
    putCompletedWorksUpdate,
} from 'shared/services/completedWorks.service';
import {$isUserVK} from 'shared/model/user';
import {ICompletedWorksDocument} from 'shared/types/completedWorksTypes';
import {TTaskList} from 'shared/types/tasksTypes';
import {getTasksList, putAcceptTask, putReturnTask} from 'shared/services/tasks.service';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {EAttachmentType} from 'shared/types/FileTypes';
import {fetchFileWithValidation} from 'shared/services/files.service';
import {downloadBlobFile} from 'shared/helpers/blob';

import {
    calcCompletion,
    calcPrevCompletion,
    completedWorksHasCurrentSpecs,
    ECompletedWorksView,
} from './helpers';
import {additionalAgreementService} from '../../DSPage/service';
import {ETaskTypeConfirmations} from '../../DSPage/types';

export const CompletedWorksViewDomain = createDomain();
export const CompletedWorksViewGate = createGate<string>();

//effects
const getCompletedWorksDocumentFx = CompletedWorksViewDomain.createEffect(async (id: string) => {
    try {
        const result = await fetchCompletedWorksSheet(id);
        return result.data;
    } catch (e) {
        console.warn(e);
    }
});

const fetchAvailableTasksFx = CompletedWorksViewDomain.createEffect(async (id: string) => {
    try {
        const result = await getTasksList({id: id});
        return result.data;
    } catch (e) {
        console.warn(e);
    }
});

const acceptTaskFx = CompletedWorksViewDomain.createEffect(async (id: string) => {
    try {
        const result = await putAcceptTask(id);
        return result.data;
    } catch (e) {
        console.warn(e);
    }
});

const returnTaskFx = CompletedWorksViewDomain.createEffect(async (id: string) => {
    try {
        const result = await putReturnTask(id);
        return result.data;
    } catch (e) {
        console.warn(e);
    }
});

export const updateTaskFx = CompletedWorksViewDomain.createEffect(
    additionalAgreementService.updateTask,
);

const sendApprovedItemFx = CompletedWorksViewDomain.createEffect(
    async (data: {id: string; action: boolean | null}) => {
        const result = await postApproveCompletedWorksSpecificationItem(data);
        return {data, result: result.status === 200};
    },
);

const getAttachmentFx = CompletedWorksViewDomain.createEffect(
    async ({fileName, fileId, type}: {fileName: string; fileId: string; type: EAttachmentType}) => {
        try {
            const result = await fetchFileWithValidation(fileId, type);
            if (result.status === 200) {
                const blob = new Blob([result.data]);
                downloadBlobFile(blob, fileName);
            }
        } catch (e) {
            console.warn(e);
        }
    },
);

const sendCommentFx = CompletedWorksViewDomain.createEffect(
    async ({id, comment}: {id: string; comment: string}) => {
        try {
            const result = await postCompletedWorksComment(id, comment);
            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },
);

const downloadAttachmentFileFx = CompletedWorksViewDomain.createEffect(async (id: string) => {
    try {
        const result = await fetchCompletedWorksFile(id);
        if (result.status === 200) {
            const blob = new Blob([result.data]);
            const fileName = result.request
                .getResponseHeader('content-disposition')
                .split("filename*=utf-8''")[1]
                .split(';')[0];
            downloadBlobFile(blob, decodeURIComponent(fileName));
        }
    } catch (e) {
        console.warn(e);
    }
});

const putCompletedWorksUpdateFx = CompletedWorksViewDomain.createEffect(async (id: string) => {
    try {
        const result = await putCompletedWorksUpdate(id);
        if (result.status !== 200) {
            snackbarNotificationChanged('Не удалось обновить ВВР');
            throw new Error();
        }
        return await getCompletedWorksDocumentFx(id);
    } catch (e) {
        console.warn(e);
    }
});

// events
export const reloadData = CompletedWorksViewDomain.createEvent();
export const acceptButtonClicked = CompletedWorksViewDomain.createEvent();
export const returnButtonClicked = CompletedWorksViewDomain.createEvent();
export const workflowButtonClicked = CompletedWorksViewDomain.createEvent<number>();
export const documentStatusChanged = CompletedWorksViewDomain.createEvent<number>();
export const subpageWorkflowDone = CompletedWorksViewDomain.createEvent<number>();
export const actionButtonClicked = CompletedWorksViewDomain.createEvent<{
    id: string;
    action: boolean | null;
}>();
export const activeSheetAccordionChanged = CompletedWorksViewDomain.createEvent<string>();
export const attachmentFetched = CompletedWorksViewDomain.createEvent<{
    fileName: string;
    fileId: string;
    type: EAttachmentType;
}>();
export const commentSent = CompletedWorksViewDomain.createEvent<string>();
export const confirmModalToggled = CompletedWorksViewDomain.createEvent<boolean>();
export const updateDocumentModalToggled = CompletedWorksViewDomain.createEvent<boolean>();
export const documentUpdated = CompletedWorksViewDomain.createEvent();
export const confirmModalPositiveBtnClicked = CompletedWorksViewDomain.createEvent();
export const editDocumentClicked = CompletedWorksViewDomain.createEvent();
const workflowTasksReset = CompletedWorksViewDomain.createEvent();
export const downloadButtonClicked = CompletedWorksViewDomain.createEvent();
export const pageModeChanged = CompletedWorksViewDomain.createEvent<ECompletedWorksView>();

// stores
const $completedWorksSheet = CompletedWorksViewDomain.createStore<ICompletedWorksDocument | null>(
    null,
);
const $activeSheetAccordion = CompletedWorksViewDomain.createStore('');
const $rawWorkflowTaskData = CompletedWorksViewDomain.createStore<TTaskList>([]);
const $negativeWorkflowButtonDisabled = CompletedWorksViewDomain.createStore(true);
const $positiveWorkflowButtonDisabled = CompletedWorksViewDomain.createStore(false);
const $openedSheetList = CompletedWorksViewDomain.createStore<
    {
        id: string;
        index: number;
        hasOpened: boolean;
    }[]
>([]);
export const $loadingAttachmentId = CompletedWorksViewDomain.createStore<string>('');
const $bannerShown = CompletedWorksViewDomain.createStore<boolean>(false);
const $anyButtonIsClicked = or(
    acceptTaskFx.pending,
    returnTaskFx.pending,
    sendApprovedItemFx.pending,
    updateTaskFx.pending,
    downloadAttachmentFileFx.pending,
    putCompletedWorksUpdateFx.pending,
);
const $dataLoaded = CompletedWorksViewDomain.createStore(false);
const $confirmModalWindowShown = CompletedWorksViewDomain.createStore(false);
const $updateModalWindowShown = CompletedWorksViewDomain.createStore(false);
export const $pageMode = CompletedWorksViewDomain.createStore<ECompletedWorksView>(
    ECompletedWorksView.normal,
);

export const $completedWorksViewPageStore = combine({
    completedWorksSheet: $completedWorksSheet,
    dataLoaded: $dataLoaded,
    activeSheet: $activeSheetAccordion,
    bannerShown: $bannerShown,
    attachmentLoading: $loadingAttachmentId,
    confirmModalWindowShown: $confirmModalWindowShown,
    updateModalWindowShown: $updateModalWindowShown,
    attachmentUnitsShown:
        $completedWorksSheet.map(
            (sheet) =>
                !!sheet?.completed_work_attachments?.length ||
                !!sheet?.completed_work_comments?.length,
        ) || $isUserVK,
    currentCompletion: $completedWorksSheet.map((sheet) => calcCompletion(sheet)),
    prevCompletion: $completedWorksSheet.map((sheet) => calcPrevCompletion(sheet)),
    widgetUnitShown: $completedWorksSheet.map(
        (sheet) =>
            !!sheet?.linked_entities.vvr_edo ||
            !!sheet?.linked_entities.vvr_edo_templates ||
            !!sheet?.linked_entities.payment_info,
    ),
});

export const $completedWorksBannerStore = combine({
    bannerShown: $bannerShown,
    openedSheetList: $openedSheetList,
});
export const $completedWorksWorkflowStore = combine({
    taskData: $rawWorkflowTaskData,
    buttonsDisabled: $anyButtonIsClicked,
    positiveButtonDisabled: $positiveWorkflowButtonDisabled,
    negativeButtonDisabled: $negativeWorkflowButtonDisabled,
});

// загрузка и перезагрузка данных
sample({
    clock: [
        CompletedWorksViewGate.state,
        acceptTaskFx.doneData,
        updateTaskFx.doneData,
        returnTaskFx.doneData,
    ],
    target: reloadData,
});

// ресет кнопок воркфлоу, после нажатия на них
sample({
    clock: [acceptTaskFx.doneData, updateTaskFx.doneData, returnTaskFx.doneData],
    target: workflowTasksReset,
});
$rawWorkflowTaskData.reset(workflowTasksReset);

//загрузка данных при открытии страницы
sample({
    clock: reloadData,
    source: CompletedWorksViewGate.state,
    filter: (id) => typeof id === 'string',
    target: [getCompletedWorksDocumentFx],
});

// запрос кнопок воркфлоу только в случае непустых vvr_current
sample({
    clock: getCompletedWorksDocumentFx.doneData,
    filter: (data) => completedWorksHasCurrentSpecs(data),
    fn: (data) => data?.id ?? '',
    target: fetchAvailableTasksFx,
});

// заполнение данных ВВР
sample({
    clock: [getCompletedWorksDocumentFx.doneData, sendCommentFx.doneData],
    filter: (data) => !!data,
    fn: (data) => data ?? null,
    target: $completedWorksSheet,
});

// дефолтное раскрытие аккордеона (раскрытие первой ведомости)
sample({
    clock: [getCompletedWorksDocumentFx.doneData, $pageMode],
    source: $completedWorksSheet,
    filter: (data) => !!data,
    fn: (data) => data?.works[0]?.id ?? '',
    target: $activeSheetAccordion,
});

// заполнение стора, следящего за открытыми ведомостями
sample({
    clock: getCompletedWorksDocumentFx.doneData,
    filter: (data) => !!data,
    fn: (data) =>
        data?.works.map((work, index) => ({
            id: work.id,
            index: index + 1,
            hasOpened: index === 0,
        })) ?? [],
    target: $openedSheetList,
});

// работа аккордеона
sample({
    clock: activeSheetAccordionChanged,
    source: $activeSheetAccordion,
    fn: (activeId, clickedId) => (clickedId === activeId ? '' : clickedId),
    target: $activeSheetAccordion,
});

sample({
    clock: activeSheetAccordionChanged,
    filter: (_, clickedItemId) => clickedItemId !== '',
    source: $openedSheetList,
    fn: (list, selectedId) =>
        list.map((item) => {
            if (item.id === selectedId) {
                return {...item, hasOpened: true};
            }
            return item;
        }),
    target: $openedSheetList,
});

// скрытие баннера, когда все ведомости просмотрены
sample({
    source: $openedSheetList,
    filter: (source) => !source.some((item) => !item.hasOpened),
    fn: () => false,
    target: $bannerShown,
});

// заполнение данных workflow
$rawWorkflowTaskData.on(fetchAvailableTasksFx.doneData, forwardPayload());

// флаг загрузки данных
sample({
    clock: getCompletedWorksDocumentFx.doneData,
    filter: (data) => !!data,
    fn: () => true,
    target: $dataLoaded,
});

// нажатие на "взять в работу"
sample({
    clock: acceptButtonClicked,
    source: $rawWorkflowTaskData,
    filter: (data) => data.length > 0,
    fn: (data) => data[0].id,
    target: acceptTaskFx,
});

// нажатие на "вернуть на группу"
sample({
    clock: returnButtonClicked,
    source: $rawWorkflowTaskData,
    filter: (data) => data.length > 0,
    fn: (data) => data[0].id,
    target: returnTaskFx,
});

//нажатие на action кнопку
sample({
    clock: actionButtonClicked,
    target: sendApprovedItemFx,
});

// обновление подтверждения спецификации без перезагрузки с бэка
sample({
    clock: sendApprovedItemFx.doneData,
    source: $completedWorksSheet,
    filter: (sheet, response) => response.result,
    fn: (sheet, response) => {
        if (!sheet) return null;
        return {
            ...sheet,
            works: sheet.works.map((work) => ({
                id: work.id,
                work_objects: work.work_objects.map((workObject) => ({
                    ...workObject,
                    specifications: {
                        ...workObject.specifications,
                        vvr_current: workObject.specifications.vvr_current.map(
                            (currentCompletedWorks) => {
                                if (currentCompletedWorks.id === response.data.id) {
                                    currentCompletedWorks.work_specification_approval =
                                        response.data.action;
                                }
                                return currentCompletedWorks;
                            },
                        ),
                    },
                })),
            })),
        } as ICompletedWorksDocument;
    },
    target: $completedWorksSheet,
});

// нажатие на воркфлоу кнопку(когда type_confirmation = 6,7 или 8)
sample({
    clock: workflowButtonClicked,
    source: $rawWorkflowTaskData,
    filter: (workflowData, index) =>
        workflowData[0]?.actions[index]?.type_confirmation >= ETaskTypeConfirmations.KZD,
    fn: (workflowData, index) => {
        switch (workflowData[0]?.actions[index]?.type_confirmation) {
            case ETaskTypeConfirmations.KZD: {
                return ECompletedWorksView.kzd;
            }
            case ETaskTypeConfirmations.PO: {
                return ECompletedWorksView.po;
            }
            case ETaskTypeConfirmations.MandatoryPO: {
                return ECompletedWorksView.mandatoryPo;
            }
            default: {
                return ECompletedWorksView.normal;
            }
        }
    },
    target: pageModeChanged,
});

// нажатие на воркфлоу кнопку
sample({
    clock: workflowButtonClicked,
    source: combine({
        sheet: $completedWorksSheet,
        workflowData: $rawWorkflowTaskData,
        isVK: $isUserVK,
    }),
    filter: ({sheet, workflowData, isVK}, index) =>
        workflowData[0]?.actions[index]?.type_confirmation < ETaskTypeConfirmations.KZD &&
        (!isVK ||
            !workflowData[0]?.actions[index]?.is_positive ||
            (sheet?.works.every((work) =>
                work.work_objects.every((object) =>
                    object.specifications.vvr_current.every(
                        (spec) => spec.work_specification_approval === true,
                    ),
                ),
            ) ??
                false)),
    fn: (_, clock) => clock,
    target: documentStatusChanged,
});

// открытие модального окна при множественном подтверждении, если не все пункты обработаны
sample({
    clock: workflowButtonClicked,
    source: combine({
        sheet: $completedWorksSheet,
        workflowData: $rawWorkflowTaskData,
        isVK: $isUserVK,
    }),
    filter: ({sheet, workflowData, isVK}, index) =>
        (workflowData[0]?.actions[index]?.type_confirmation !== ETaskTypeConfirmations.KZD &&
            isVK &&
            workflowData[0]?.actions[index]?.is_positive &&
            !sheet?.works.every((work) =>
                work.work_objects.every((object) =>
                    object.specifications.vvr_current.every(
                        (spec) => spec.work_specification_approval === true,
                    ),
                ),
            )) ??
        false,
    fn: () => true,
    target: confirmModalToggled,
});

// клик на "согласовать" в модальном окне согласования
sample({
    clock: confirmModalPositiveBtnClicked,
    fn: () => false,
    target: confirmModalToggled,
});

// клик на "согласовать" в модальном окне согласования
sample({
    clock: confirmModalPositiveBtnClicked,
    source: $rawWorkflowTaskData,
    filter: (source) => !!source[0]?.actions.find((item) => item.is_positive),
    fn: (source) => source[0]?.actions.findIndex((item) => item.is_positive),
    target: documentStatusChanged,
});

// клик на кнопку воркфлоу (неуспешный флоу - не были просмотрены все ведомости)
sample({
    clock: documentStatusChanged,
    source: combine({
        buttonsData: $rawWorkflowTaskData,
        isVK: $isUserVK,
        sheetList: $openedSheetList,
    }),
    filter: ({isVK, buttonsData, sheetList}, index) =>
        isVK &&
        buttonsData[0]?.actions[index]?.is_positive === true &&
        sheetList.some((item) => !item.hasOpened),
    fn: () => true,
    target: $bannerShown,
});

// клик на кнопку воркфлоу (успешный флоу KZD, PO)
sample({
    clock: subpageWorkflowDone,
    source: combine({
        buttonsData: $rawWorkflowTaskData,
    }),
    fn: ({buttonsData}, index) => ({
        id: buttonsData[0]?.id ?? '',
        actionId: buttonsData[0]?.actions[index]?.id ?? '',
    }),
    target: updateTaskFx,
});

// клик на кнопку воркфлоу (успешный флоу)
sample({
    clock: documentStatusChanged,
    source: combine({
        buttonsData: $rawWorkflowTaskData,
        bannerShown: $bannerShown,
        isVK: $isUserVK,
        sheetList: $openedSheetList,
    }),
    filter: ({bannerShown, isVK, buttonsData, sheetList}, index) =>
        !isVK ||
        !bannerShown ||
        !sheetList.some((item) => !item.hasOpened) ||
        buttonsData[0]?.actions[index]?.is_positive !== true,
    fn: ({buttonsData}, index) => ({
        id: buttonsData[0]?.id ?? '',
        actionId: buttonsData[0]?.actions[index]?.id ?? '',
    }),
    target: updateTaskFx,
});

// блокировка позитивной workflow кнопки, если есть хоть одно отклонение
sample({
    clock: $completedWorksSheet,
    source: $rawWorkflowTaskData,
    filter: (taskData) => (taskData[0]?.status ?? 0) === 2,
    fn: (taskData, sheet) => {
        if (!sheet) return true;
        return sheet.works.some((work) =>
            work.work_objects.some((object) =>
                object.specifications.vvr_current.some(
                    (spec) => spec.work_specification_approval === false,
                ),
            ),
        );
    },
    target: $positiveWorkflowButtonDisabled,
});

// блокировка негативной workflow кнопки, если есть хоть одно невыбранное поле
sample({
    clock: $completedWorksSheet,
    source: $rawWorkflowTaskData,
    filter: (taskData) => (taskData[0]?.status ?? 0) === 2,
    fn: (taskData, sheet) => {
        if (!sheet) return true;
        return !sheet.works.every((work) =>
            work.work_objects.every((object) =>
                object.specifications.vvr_current.every(
                    (spec) => spec.work_specification_approval !== null,
                ),
            ),
        );
    },
    target: $negativeWorkflowButtonDisabled,
});

// логика работы аттачментов
sample({
    clock: attachmentFetched,
    target: getAttachmentFx,
});

// отправка коммента на бэк
sample({
    clock: commentSent,
    source: $completedWorksSheet,
    filter: (store, comment) => comment !== '' && !!store,
    fn: (store, comment) => ({id: store?.id ?? '', comment}),
    target: sendCommentFx,
});

// заполнения стора, показывающего какое вложение сейчас загружается
sample({
    clock: getAttachmentFx,
    fn: (attachmentParams) => attachmentParams.fileId,
    target: $loadingAttachmentId,
});

// тоггл модального окна подтверждения согласования ВВР
sample({
    clock: confirmModalToggled,
    target: $confirmModalWindowShown,
});

// нажатие на кнопку "редактировать" (без актуализации)
sample({
    clock: editDocumentClicked,
    source: $completedWorksSheet,
    filter: (sheet) => !!sheet && !sheet.need_update_agreement,
    fn: (sheet) => sheet,
    target: completedWorksDocumentEdited,
});

// нажатие на кнопку "редактировать" (ВВР нуждается в актуализация, открывается модальное окно)
sample({
    clock: editDocumentClicked,
    source: $completedWorksSheet,
    filter: (sheet) => !!sheet && sheet.need_update_agreement,
    fn: () => true,
    target: updateDocumentModalToggled,
});

// тоггл модального окна актуализации ВВР
sample({
    clock: updateDocumentModalToggled,
    target: $updateModalWindowShown,
});

// актуализация ВВР
sample({
    clock: documentUpdated,
    source: $completedWorksSheet,
    filter: (agreement) => !!agreement,
    fn: (agreement) => agreement?.id ?? '',
    target: putCompletedWorksUpdateFx,
});

// передача обновленного ВВР в стор конструктора ВВР с последующим редиректом
sample({
    clock: putCompletedWorksUpdateFx.doneData,
    filter: (sheet) => !!sheet,
    fn: (sheet) => sheet ?? null,
    target: completedWorksDocumentEdited,
});

sample({
    clock: downloadButtonClicked,
    source: $completedWorksSheet,
    fn: (source) => source?.id ?? '',
    target: downloadAttachmentFileFx,
});

// смена режима страницы (нормальное отображение/подстраница создания КЗД)
sample({
    clock: pageModeChanged,
    target: $pageMode,
});

$loadingAttachmentId.reset(getAttachmentFx.doneData);

resetDomainStoresByEvents(CompletedWorksViewDomain, CompletedWorksViewGate.close);
