import React, {useState} from 'react';
import {Button, Typography} from '@beeline/design-system-react';

import {IComment} from 'pages/DSPage/types';
import {DownloadableAttachment} from 'Features/DownloadableAttachment';
import {TrimTextArea} from 'Features/TrimTextArea';
import {Comment} from 'Entities/Comment';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {EAttachmentType, TAttachmentFile} from 'shared/types/FileTypes';
import {
    ADDITIONAL_AGREEMENT_SHORT_TITLE,
    ATTACHMENTS_TITLE,
    DOCUMENT_COMMENTS_TITLE,
    OF_TITLE,
    SAVE_BUTTON,
    WRITE_YOUR_COMMENT_HELPER,
} from 'shared/constants';

import * as S from './style';

interface DsCommentsBlockProps {
    attachmentList?: TAttachmentFile[];
    parentAttachmentList?: TAttachmentFile[];
    commentList?: IComment[];
    parentCommentList?: IComment[];
    handleSaveComment: (comment: string) => void;
    handleDownloadAttachment: (fileId: string, fileName: string, type: EAttachmentType) => void;
    loadingAttachmentId: string;
}

export const DsCommentsBlock = ({
    attachmentList,
    commentList,
    parentAttachmentList,
    parentCommentList,
    handleSaveComment,
    handleDownloadAttachment,
    loadingAttachmentId,
}: DsCommentsBlockProps) => {
    const [commentFieldValue, setCommentFieldValue] = useState<string>('');

    const commentsCount = `${!!commentList?.length ? ` (${commentList?.length})` : ''}`;

    const handleChangeTextArea = (text: string) => {
        setCommentFieldValue(text);
    };

    const handleClickSaveBtn = () => {
        handleSaveComment(commentFieldValue);
        setCommentFieldValue('');
    };

    return (
        <WrapperOffset offsetTop={24} offsetBottom={24}>
            <S.CustomWrapper>
                <S.AddsWrap>
                    {!!attachmentList?.length && (
                        <>
                            <S.StyledTypography variant="h5" mb="8px">
                                {ATTACHMENTS_TITLE}
                            </S.StyledTypography>
                            <div>
                                {attachmentList.map((item) => (
                                    <DownloadableAttachment
                                        id={item.id ?? ''}
                                        key={item.id}
                                        type={item.type}
                                        title={item.file_name}
                                        handleDownloadAttachment={handleDownloadAttachment}
                                        disabled={loadingAttachmentId === item.id}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                    {!!parentAttachmentList?.length && (
                        <>
                            <S.StyledTypography variant="h5" mt="16px" mb="8px">
                                {`${ATTACHMENTS_TITLE} ${OF_TITLE} ${ADDITIONAL_AGREEMENT_SHORT_TITLE}`}
                            </S.StyledTypography>
                            {parentAttachmentList.map((item) => (
                                <DownloadableAttachment
                                    id={item.id ?? ''}
                                    key={item.id}
                                    type={item.type}
                                    title={item.file_name}
                                    handleDownloadAttachment={handleDownloadAttachment}
                                    disabled={loadingAttachmentId === item.id}
                                />
                            ))}
                        </>
                    )}
                </S.AddsWrap>
                <S.CommentWrap>
                    <Typography variant="h5">
                        {`${DOCUMENT_COMMENTS_TITLE}${commentsCount}`}
                    </Typography>
                    <S.TextAreaWrapper>
                        <TrimTextArea
                            value={commentFieldValue}
                            label={WRITE_YOUR_COMMENT_HELPER}
                            onChange={handleChangeTextArea}
                            fullWidth
                        />
                        <S.RightButton>
                            <Button size="medium" onClick={handleClickSaveBtn}>
                                {SAVE_BUTTON}
                            </Button>
                        </S.RightButton>
                    </S.TextAreaWrapper>

                    {commentList &&
                        commentList.length > 0 &&
                        commentList.map((item) => <Comment key={item.created_at} {...item} />)}
                    {!!parentCommentList?.length && (
                        <>
                            <S.StyledTypography variant="h5" mt="16px">
                                {`${ATTACHMENTS_TITLE} ${OF_TITLE} ${ADDITIONAL_AGREEMENT_SHORT_TITLE}`}
                            </S.StyledTypography>
                            {parentCommentList.map((item) => (
                                <Comment key={item.created_at} {...item} />
                            ))}
                        </>
                    )}
                </S.CommentWrap>
            </S.CustomWrapper>
        </WrapperOffset>
    );
};
