import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {cloneDeep} from 'lodash';
import {debounce, or} from 'patronum';
import {Option} from '@beeline/design-system-react';

import {$createAdditionalAgreementData} from 'processes/contructor';
import {$vatRate} from 'shared/model/user';
import {
    getAdditionalAgreementListForCopingWorksheets,
    fetchWorksheetListForCoping,
} from 'shared/services/ds.service';
import {
    ICopingAdditionalAgreement,
    IAgreementCopingWorksheet,
    ITemporaryCopingAgreement,
} from 'shared/types/additionalAgreementsTypes';
import {TGetWorksheetListForCopingParams} from 'shared/services/types/agreementParamsTypes';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';

import {
    changeAllSpecificationsChecked,
    changeObjectLabel,
    changeObjectWorkType,
    changeSpecificationChecked,
    changeWorkObjectChecked,
    initialState,
    prepareCopiedAgreementToConstructor,
    transformToTemporaryAgreementObject,
} from './helpers';
import {
    $selectedContract,
    $workObjectsOptions,
    $workTypOptions,
    $constructorStage1SelectedOptions,
} from '../../VirStart/model';
import {CatalogWorkObjectOption} from '../../VirStart/types';

export const WorksheetCopyDomain = createDomain();
export const WorksheetCopyGate = createGate();

// effects
const getAvailableAgreementListFx = WorksheetCopyDomain.createEffect(
    async ({contractId, search}: {contractId: string; search: string}) => {
        const result = await getAdditionalAgreementListForCopingWorksheets(contractId, search);
        return result.data.results;
    },
);

const getWorksheetListForCopingFx = WorksheetCopyDomain.createEffect(
    async (params: TGetWorksheetListForCopingParams) => {
        try {
            const result = await fetchWorksheetListForCoping(params);
            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },
);

// events
export const searchStringChanged = WorksheetCopyDomain.createEvent<string>();
export const copiedAgreementSelected =
    WorksheetCopyDomain.createEvent<Option<ICopingAdditionalAgreement> | null>();
export const worksheetSelected = WorksheetCopyDomain.createEvent<string>();
export const destinationWorksheetObjectSelected = WorksheetCopyDomain.createEvent<{
    values: CatalogWorkObjectOption[];
    objectId: string;
}>();
export const quantityChecked = WorksheetCopyDomain.createEvent<boolean>();
export const coefficientChecked = WorksheetCopyDomain.createEvent<boolean>();
export const actionButtonClicked = WorksheetCopyDomain.createEvent();

export const workTypeOptionSelected = WorksheetCopyDomain.createEvent<{
    worksheetId: string;
    objectId: string;
    workTypeOption: {
        id: string;
        value: string;
    };
}>();
export const specificationSelected = WorksheetCopyDomain.createEvent<{
    worksheetId: string;
    objectId: string;
    specificationId: string;
}>();
export const workObjectSelected = WorksheetCopyDomain.createEvent<{
    worksheetId: string;
    objectId: string;
    checked: boolean;
}>();
export const allSpecificationsSelected = WorksheetCopyDomain.createEvent<{
    worksheetId: string;
    objectId: string;
    checked: boolean;
}>();

const copiedAgreementReset = WorksheetCopyDomain.createEvent();

// stores
const $searchString = WorksheetCopyDomain.createStore<string>('').on(
    searchStringChanged,
    forwardPayload(),
);
const $contractName = WorksheetCopyDomain.createStore<string>('');
const $quantityCheck = WorksheetCopyDomain.createStore<boolean>(true);
const $coefficientCheck = WorksheetCopyDomain.createStore<boolean>(true);
const $availableAgreementList = WorksheetCopyDomain.createStore<
    Option<ICopingAdditionalAgreement>[]
>([]).on(getAvailableAgreementListFx.doneData, (_, payload) =>
    payload
        .map((item) => {
            return item.addendum ? [item, ...item.addendum] : item;
        })
        .flat()
        .map((item) => ({
            id: item.id,
            value: item,
        })),
);
const $availableWorksheetObjects = WorksheetCopyDomain.createStore<Array<CatalogWorkObjectOption>>(
    [],
);
const $initialCopiedAgreement = WorksheetCopyDomain.createStore<ITemporaryCopingAgreement | null>(
    null,
);
const $finalCopiedAgreement = WorksheetCopyDomain.createStore<ITemporaryCopingAgreement | null>(
    null,
);

export const $workTypeOptions = $workTypOptions.map((item) =>
    item.map((workType) => ({
        id: workType.work_types_id,
        value: workType.work_types_name,
    })),
);

const $selectedAgreement =
    WorksheetCopyDomain.createStore<Option<ICopingAdditionalAgreement> | null>(null).on(
        copiedAgreementSelected,
        forwardPayload(),
    );

const $copiedAgreement = WorksheetCopyDomain.createStore<IAgreementCopingWorksheet | null>(null).on(
    getWorksheetListForCopingFx.doneData,
    forwardPayload(),
);

export const $selectedWorksheetList = WorksheetCopyDomain.createStore<string[]>([]);
const $navigateToConstructor = WorksheetCopyDomain.createStore<boolean>(false);
const $selectedTpi = WorksheetCopyDomain.createStore<string>('');
const $goAheadButtonDisabled = WorksheetCopyDomain.createStore<boolean>(false);

export const $copyAgreementConstructorStore = combine({
    availableAgreementList: $availableAgreementList,
    agreementListLoading: or(
        getAvailableAgreementListFx.pending,
        getWorksheetListForCopingFx.pending,
    ),
    selectedAgreementLoading: getWorksheetListForCopingFx.pending,
    contractName: $contractName,
    searchString: $searchString,
    selectedAgreement: $selectedAgreement,
    copiedAgreement: $copiedAgreement,
    initialCopiedAgreement: $initialCopiedAgreement,
    finalCopiedAgreement: $finalCopiedAgreement,
    worksheetObjectList: $availableWorksheetObjects,
    quantityCheck: $quantityCheck,
    coefficientCheck: $coefficientCheck,
    navigateToConstructor: $navigateToConstructor,
    selectedTpiName: $selectedTpi,
    goAheadButtonDisabled: $goAheadButtonDisabled,
});

export const $agreementObjectSelectStore = combine({
    availableWorksheetObjects: $availableWorksheetObjects,
});

// сброс данных при сбросе/выборе ДС в автокомплите
$selectedWorksheetList.reset(copiedAgreementSelected);

// копирование contractId из первой страницы конструктора при открытии
sample({
    clock: [WorksheetCopyGate.open],
    source: $selectedContract,
    fn: (source) => source?.contract_number ?? '',
    target: $contractName,
});

// копирование объектов для для последующего копирования пунктов ВИР в них
sample({
    clock: [WorksheetCopyGate.open],
    source: $workObjectsOptions,
    target: $availableWorksheetObjects,
});

// получение списка ДС при открытии страницы и изменении поисковой строки в автокомплите
sample({
    clock: [WorksheetCopyGate.open, debounce({source: $searchString, timeout: 500})],
    source: combine({
        contractId: $selectedContract,
        search: $searchString,
    }),
    filter: (source) => !!source.contractId && !!source.contractId.framework_contract_id,
    fn: (source) => {
        return {
            contractId: source.contractId?.framework_contract_id ?? '',
            search: source.search,
        };
    },
    target: getAvailableAgreementListFx,
});

// загрузка ВИРов для копирования при выборе ДС
sample({
    clock: $selectedAgreement,
    source: $constructorStage1SelectedOptions,
    filter: ({selectedProject, selectedTpi}, selectedAgreement) =>
        !!selectedTpi && !!selectedProject && !!selectedAgreement,
    fn: ({selectedProject, selectedTpi}, selectedAgreement) =>
        ({
            id: selectedAgreement?.value.id ?? '',
            projectId: selectedProject?.project_id ?? '',
            tpiId: selectedTpi?.tpis_id ?? '',
            remoteTerritoryId: selectedTpi?.remote_territory_id,
        } as TGetWorksheetListForCopingParams),
    target: getWorksheetListForCopingFx,
});

// заполнение ТЦП под срокой "Куда"
sample({
    source: $constructorStage1SelectedOptions,
    filter: ({selectedTpi}) => !!selectedTpi,
    fn: ({selectedTpi}) => selectedTpi?.tpis_name ?? '',
    target: $selectedTpi,
});

// трансформирование объекта ДС
sample({
    clock: $copiedAgreement,
    source: $constructorStage1SelectedOptions,
    filter: ({selectedProject, selectedTpi, selectedContract, workObjectList}, copiedAgreement) =>
        !!copiedAgreement &&
        !!selectedProject &&
        !!selectedTpi &&
        !!selectedContract &&
        !!workObjectList,
    fn: ({selectedProject, selectedTpi, selectedContract, workObjectList}, copiedAgreement) =>
        !!copiedAgreement &&
        !!selectedProject &&
        !!selectedTpi &&
        !!selectedContract &&
        !!workObjectList
            ? transformToTemporaryAgreementObject(
                  copiedAgreement,
                  workObjectList,
                  selectedTpi,
                  selectedContract,
                  selectedProject,
              )
            : null,
    target: $initialCopiedAgreement,
});

// глубокая копия для того, чтобы показывать изначальные типы работ у объектов
sample({
    source: $initialCopiedAgreement,
    fn: (source) => cloneDeep(source),
    target: $finalCopiedAgreement,
});

// работа глобальных чекбоксов ВИРов
sample({
    clock: worksheetSelected,
    source: $selectedWorksheetList,
    fn: (source, selectedId) => {
        let result = [...source];
        if (result.includes(selectedId)) {
            result = result.filter((item) => item !== selectedId);
        } else {
            result.push(selectedId);
        }
        return result;
    },
    target: $selectedWorksheetList,
});

sample({
    clock: destinationWorksheetObjectSelected,
    source: $finalCopiedAgreement,
    filter: (agreement, clock) => clock.values.length > 0 && !!agreement,
    fn: (agreement, {values, objectId}) => {
        if (agreement) {
            return changeObjectLabel(values[0], objectId, agreement);
        }
        return null;
    },
    target: $finalCopiedAgreement,
});

// сброс копируемых ведомостей
sample({
    clock: searchStringChanged,
    filter: (clock) => clock === '',
    target: copiedAgreementReset,
});

$coefficientCheck.on(coefficientChecked, forwardPayload());
$quantityCheck.on(quantityChecked, forwardPayload());

// изменение выбранных типов работ в объектах
sample({
    clock: workTypeOptionSelected,
    source: $finalCopiedAgreement,
    filter: (source) => !!source,
    fn: (agreement, clock) => (!!agreement ? changeObjectWorkType(agreement, clock) : null),
    target: $finalCopiedAgreement,
});

// изменение выбранных пунктов спецификации в объектах
sample({
    clock: specificationSelected,
    source: $finalCopiedAgreement,
    filter: (source) => !!source,
    fn: (agreement, clock) => (!!agreement ? changeSpecificationChecked(agreement, clock) : null),
    target: $finalCopiedAgreement,
});

// нажатие "выбрать объект"
sample({
    clock: workObjectSelected,
    source: $finalCopiedAgreement,
    filter: (source) => !!source,
    fn: (agreement, clock) => (!!agreement ? changeWorkObjectChecked(agreement, clock) : null),
    target: $finalCopiedAgreement,
});

// нажатие "выбрать все спецификации"
sample({
    clock: allSpecificationsSelected,
    source: $finalCopiedAgreement,
    filter: (source) => !!source,
    fn: (agreement, clock) =>
        !!agreement ? changeAllSpecificationsChecked(agreement, clock) : null,
    target: $finalCopiedAgreement,
});

// если нет ведомостей для переноса, то кнопка "перенести пункты" заблокирована
sample({
    source: $finalCopiedAgreement,
    filter: (agreement) => !!agreement,
    fn: (agreement) => {
        return !agreement?.works.some((worksheet) => worksheet.checked);
    },
    target: $goAheadButtonDisabled,
});

// нажатие на "перенести пункты"
sample({
    clock: actionButtonClicked,
    source: combine({
        copiedAgreement: $finalCopiedAgreement,
        transferQuantity: $quantityCheck,
        transferCoefficients: $coefficientCheck,
        availableObjectList: $availableWorksheetObjects,
        vatRate: $vatRate,
    }),
    fn: ({copiedAgreement, transferQuantity, transferCoefficients, availableObjectList, vatRate}) =>
        !!copiedAgreement
            ? prepareCopiedAgreementToConstructor(
                  copiedAgreement,
                  availableObjectList,
                  vatRate,
                  transferQuantity,
                  transferCoefficients,
              )
            : initialState,
    target: $createAdditionalAgreementData,
});

sample({
    clock: actionButtonClicked,
    fn: () => true,
    target: $navigateToConstructor,
});

// копируемые ведомости сбрасываются при сбросе автокоплите и при выборе ДС в автокомплите
$copiedAgreement.reset([copiedAgreementReset, copiedAgreementSelected]);

// сброс данных при уходе со страницы
resetDomainStoresByEvents(WorksheetCopyDomain, WorksheetCopyGate.close);
