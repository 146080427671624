import {
    COMMA_SEPARATOR,
    DOT_SEPARATOR,
    EMPTY_NUMBERED_VALUE,
    EMPTY_STRING,
    FORMATS_STRING,
    SPACE_SEPARATOR,
} from '../../constants';
import {MIN_FILES_COUNT_TEXT, SIZE_LESS_THEN} from './constants';

import {MAX_FILES_COUNT_TEXT} from './constants';

const formatBytes = (fileSize: number) => {
    if (!+fileSize) return '0 байт';

    const k = 1024;
    const sizes = ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];

    const i = Math.floor(Math.log(fileSize) / Math.log(k));

    return `${parseFloat((fileSize / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

export const getFilesRestrictionsText = (
    acceptFormats?: string[],
    maxFileSize?: number,
    minFiles?: number,
    maxFiles?: number,
): string => {
    const formatsSeparator = `${COMMA_SEPARATOR}${SPACE_SEPARATOR}`;
    const acceptFormatsPart = `${FORMATS_STRING} ${(acceptFormats || []).join(formatsSeparator)}`;

    const maxFileSizeFormatted =
        (maxFileSize as number) > EMPTY_NUMBERED_VALUE
            ? formatBytes(maxFileSize as number)
            : EMPTY_STRING;
    const maxFileSizePart = maxFileSizeFormatted
        ? `${SIZE_LESS_THEN} ${maxFileSizeFormatted}`
        : maxFileSizeFormatted;

    const minFilesCountPart =
        (minFiles as number) > EMPTY_NUMBERED_VALUE
            ? `${DOT_SEPARATOR} ${MIN_FILES_COUNT_TEXT} ${minFiles}`
            : EMPTY_STRING;
    const maxFilesCountPart =
        (maxFiles as number) > EMPTY_NUMBERED_VALUE
            ? `${DOT_SEPARATOR} ${MAX_FILES_COUNT_TEXT} ${maxFiles}`
            : EMPTY_STRING;

    return `${acceptFormatsPart} ${maxFileSizePart}${minFilesCountPart}${maxFilesCountPart}`;
};
