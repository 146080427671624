import {createDomain, forward, sample} from 'effector';
import {createGate} from 'effector-react';
import {cloneDeep, isEqual} from 'lodash';

import {defaultColumns} from 'pages/ProjectPage/helpers';
import {saveOwnerId} from 'pages/VirStart/model';
import {getAccountInfo, postUserSettings} from 'shared/services/user.service';
import {authFailed, authSuccessful, toggleLoading} from 'shared/model/auth';
import {getAuthorization, getValidateAccount} from 'shared/services/auth.service';
import {UserInfoType} from 'shared/services/types/userTypes';
import {TExtended} from 'shared/types/userTypes';
import {navigationInvoked} from 'app/providers/AppNavigator/model';

import {EAccessActions} from 'shared/const/actions';
import {
    checkContractsPageValidity,
    getInitialContractsPageData,
    getInitialProcessesPageData,
} from './helpers';

const userDomain = createDomain();
export const UserGate = createGate();

//events
export const getUserInfo = userDomain.createEvent();
export const setColumnSettings = userDomain.createEvent<TExtended>();

// effects
export const fetchUserInfoFx = userDomain.createEffect(async () => {
    try {
        toggleLoading(true);
        const result = await getAccountInfo();
        if (result.status === 200) {
            authSuccessful();
            saveOwnerId(result.data?.owner_id);
            return result.data;
        }

        const ticket = localStorage.getItem('ticketId');

        if (ticket) {
            const tokenResponse = await getValidateAccount(ticket);
            if (tokenResponse.status !== 200) {
                localStorage.removeItem('ticketId');
                throw Error();
            }
            localStorage.setItem('accessToken', tokenResponse.data.accessToken);
            localStorage.setItem('refreshToken', tokenResponse.data.refreshToken);
            localStorage.removeItem('ticketId');
            const userInfoResult = await getAccountInfo();
            if (userInfoResult.status === 200) {
                authSuccessful();
                return userInfoResult.data;
            }
        } else {
            const authResult = await getAuthorization(window.location.origin);

            if (authResult.status === 200) {
                localStorage.setItem('ticketId', authResult.data.ticketId);
                window.location.replace(authResult.data.redirectUrl);
                // @ts-ignore
            } else if (authResult.response.status === 301) {
                navigationInvoked({to: '/login'});
            } else {
                localStorage.removeItem('ticketId');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                throw Error();
            }
        }
    } catch (e) {
        console.error(e);
        authFailed();
    } finally {
        toggleLoading(false);
    }
});

export const postColumnSettingsFx = userDomain.createEffect(async (data: TExtended) => {
    const result = await postUserSettings(data);
    return result.data.extend;
});

// stores
export const $userStore = userDomain
    .createStore<UserInfoType>({
        email: '',
        user_type: 0,
        first_name: '',
        is_tax: true,
        tax_rate: null,
        last_name: '',
        telephone: '',
        owner_id: '',
        actions: [],
        extend: {
            projectsPage: {
                columns: defaultColumns,
            },
            processesPage: getInitialProcessesPageData(),
            contractsPage: getInitialContractsPageData(),
        } as TExtended,
    })
    .on(fetchUserInfoFx.doneData, (state, payload) => {
        try {
            if (!payload) throw Error('нет данных о пользователе');
            if (
                !!payload &&
                (payload.extend === null ||
                    payload.extend === undefined ||
                    Object.keys(payload.extend ?? {}).length === 0)
            ) {
                payload.extend = {
                    processesPage: getInitialProcessesPageData(),
                    projectsPage: {columns: defaultColumns},
                    contractsPage: getInitialContractsPageData(),
                };
            } else if (
                !!payload &&
                (payload.extend.processesPage === null ||
                    !Array.isArray(payload.extend.processesPage.tasksColumns) ||
                    !Array.isArray(payload.extend.processesPage.processesColumns) ||
                    payload.extend.processesPage.tasksColumns.length < 12 ||
                    payload.extend.processesPage.processesColumns.length < 10)
            ) {
                payload.extend.processesPage = getInitialProcessesPageData();
            }
            if (!!payload && !checkContractsPageValidity(payload.extend)) {
                payload.extend.contractsPage = getInitialContractsPageData();
            }
        } catch (e) {
            if (!!payload) {
                payload.extend = {
                    processesPage: getInitialProcessesPageData(),
                    projectsPage: {columns: defaultColumns},
                    contractsPage: getInitialContractsPageData(),
                };
                return payload;
            }
            return undefined;
        }
        return payload;
    })
    .on(postColumnSettingsFx.doneData, (state, payload) => ({...state, extend: payload}));
export const $isUserGpo = userDomain.createStore<boolean>(false);
export const $isUserVK = userDomain.createStore<boolean>(false);
export const $userHasVAT = userDomain.createStore<boolean>(false);
export const $vatRate = userDomain.createStore<number | null>(null);
export const $userActions = userDomain.createStore<EAccessActions[]>([]);

sample({
    source: $userStore,
    fn: (source) => source.user_type === 6 || source.user_type === 4,
    target: $isUserGpo,
});

sample({
    source: $userStore,
    fn: (source) => source.user_type === 2 || source.user_type === 5,
    target: $isUserVK,
});

sample({
    source: $userStore,
    filter: (source) => !!source.actions,
    fn: (source) => source.actions,
    target: $userActions,
});

sample({
    source: $userStore,
    fn: (source) => source.is_tax,
    target: $userHasVAT,
});

sample({
    source: $userStore,
    fn: (source) => source.tax_rate,
    target: $vatRate,
});

forward({from: UserGate.open, to: getUserInfo});

forward({from: getUserInfo, to: fetchUserInfoFx});

sample({
    clock: setColumnSettings,
    source: $userStore,
    filter: (source, clock) => {
        const store = cloneDeep(clock);
        if (!!store.processesPage.filters.search) {
            store.processesPage.filters.search = '';
        }
        if (!!store.processesPage.filters.search) {
            store.processesPage.filters.search = '';
        }

        return !isEqual(store, source.extend);
    },
    fn: (_, clock) => clock,
    target: postColumnSettingsFx,
});
