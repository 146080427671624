import React, {ChangeEventHandler} from 'react';
import {TextArea} from '@beeline/design-system-react';
import {TextAreaSize} from '@beeline/design-system-react/types/components/TextArea/TextArea.types';

import {trimUnnecessaryNewLines} from 'shared/helpers/textFormattings';

interface TrimTextAreaProps {
    value: string | undefined;
    onChange: (value: string) => void;
    error?: boolean;
    helperText?: string;
    label?: string;
    fullWidth?: boolean;
    size?: TextAreaSize;
}

// Обертка над TextArea для удаления 3+ \n символов
export const TrimTextArea = (data: TrimTextAreaProps) => {
    const {onChange} = data;
    const handleOnChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        onChange(trimUnnecessaryNewLines(e.currentTarget.value));
    };
    return <TextArea {...data} onChange={handleOnChange} />;
};
