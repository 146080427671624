import styled, {css} from 'styled-components/macro';
import {Typography} from '@beeline/design-system-react';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 496px;
    margin: 32px auto;
`;

export const OffsetTypography = styled(Typography)<{$offset?: boolean}>`
    ${(p) =>
        p.$offset &&
        css`
            padding-left: 40px;
        `}
`;
