import {combine, merge, sample} from 'effector';
import {v4 as uuidv4} from 'uuid';

import {setActiveTab} from 'pages/VirSelect/model/SelectTpisPage';
import {$vatRate} from 'shared/model/user';
import {calcVatSum, calcTotalSum} from 'shared/helpers/calculators';
import {compareStringOfNumbers, getUniqueListBy} from 'shared/helpers/compareStringOfNumbers';
import {forwardPayload} from 'shared/helpers/effector';
import {formatNumber} from 'shared/helpers/formatHelper';

import {
    $count,
    $page,
    $pageSize,
    $selectedTpis,
    $selectedTpisOnPage,
    $selectedSearchTerm,
    changeSearchSelectedInput,
    duplicatePoint,
    filterSelectedBySearchTerm,
    selectPoint,
    selectTpis,
    setPage,
    setPageSize,
    unselectAll,
    unselectPoint,
    unselectPointGroup,
} from './index';

$selectedSearchTerm.on(changeSearchSelectedInput, forwardPayload()).reset(setActiveTab);

// unselectAll удаляет только доступные для удаления пункты
$selectedTpis
    .on(unselectAll, (tpiList) => tpiList.filter((item) => item.is_ws_deletable === false))
    .on(selectTpis, (existing, newTpis) => {
        const filteredTpis = getUniqueListBy([...existing, ...newTpis], 'id');
        return filteredTpis.sort((a, b) => compareStringOfNumbers(a.number, b.number));
    })
    .on(selectPoint, (existing, newPoint) => {
        if (!existing.length) {
            return [newPoint];
        }
        const updatedTpis = [...existing, newPoint];
        return updatedTpis.sort((a, b) => compareStringOfNumbers(a.number, b.number));
    })
    .on(unselectPointGroup, (existing, newPoint) => {
        return existing.filter((el) => el.tpi_specification_id !== newPoint.tpi_specification_id);
    })
    .on(unselectPoint, (existing, newPoint) => {
        return existing.filter((el) => el.id !== newPoint.id);
    });

// перенос обработки ивента duplicatePoint в sample, т.к. требуется vatRate из user стора
sample({
    clock: duplicatePoint,
    source: combine({
        existing: $selectedTpis,
        vatRate: $vatRate,
    }),
    fn: ({existing, vatRate}, specToDuplicate) => {
        const index = existing.findIndex((el) => el.id === specToDuplicate.id);
        const price = specToDuplicate.is_agreed_price ? 0 : specToDuplicate.price;
        const sum = formatNumber(price * specToDuplicate.quantity);
        const duplicate = {
            ...specToDuplicate,
            master_position: undefined,
            id: `front -${uuidv4()}`,
            price,
            quantity: 1,
            sum,
            sum_tax: calcVatSum(sum, vatRate),
            total_sum: calcTotalSum(sum, vatRate),
            begin_date: specToDuplicate.default_begin_date,
            end_date: specToDuplicate.default_end_date,
        };
        return [...existing.slice(0, index + 1), duplicate, ...existing.slice(index + 1)].map(
            (tpi, index) => ({
                ...tpi,
                ordinal_number: index + 1,
            }),
        );
    },
    target: $selectedTpis,
});

export const tableTriggers = merge([
    setPage,
    setPageSize,
    filterSelectedBySearchTerm,
    setActiveTab,
    selectPoint,
    unselectPoint,
    selectTpis,
    unselectPointGroup,
    duplicatePoint,
    unselectAll,
]);

const filtered = sample({
    clock: tableTriggers,
    source: combine({
        allTpis: $selectedTpis,
        searchTerm: $selectedSearchTerm,
    }),
    fn: ({allTpis, searchTerm}) => {
        const regexp = new RegExp(searchTerm, 'i');
        const searchByNumber = /[\.*\d*]/.test(searchTerm);
        return allTpis.filter((el) => {
            const testName = regexp.test(el.name);
            const testNumber = regexp.test(el.number);
            const testNote = el?.note && regexp.test(el.note);
            return searchTerm
                ? searchByNumber
                    ? testNumber
                    : el?.note
                    ? testName || testNote
                    : testName
                : el;
        });
    },
});

sample({
    clock: filtered,
    source: combine({
        page: $page,
        pageSize: $pageSize,
    }),
    fn: ({page, pageSize}, results) => results.slice((page - 1) * pageSize, page * pageSize),
    target: $selectedTpisOnPage,
});

$page
    .on(setPage, (state, page) => page)
    .reset([setPageSize, setActiveTab, filterSelectedBySearchTerm]);
$pageSize.on(setPageSize, forwardPayload());

sample({
    clock: filtered,
    fn: ({length}) => length,
    target: $count,
});
