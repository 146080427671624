import React from 'react';
import {TableData, Typography} from '@beeline/design-system-react';

import {WorkObjectNameRow} from 'Entities/WorkObjectNameRow/ui/WorkObjectNameRow';
import {Summary} from 'pages/VirConstructor/Summary/Summary';
import {IStatus} from 'shared/types/statusTypes';
import {ICompletedWorkObject} from 'shared/types/completedWorksTypes';
import {createAgreementWorkObjectName} from 'shared/helpers/additionalAgreements';
import {formatNumber} from 'shared/helpers/formatHelper';
import {calcVatSum} from 'shared/helpers/calculators';

import {SpecificationRow} from './SpecificationRow/SpecificationRow';

interface WorkObjectProps {
    touchedConstructor: boolean;
    workId: string;
    workObject: ICompletedWorkObject;
    status: IStatus;
}

export const WorkObject = ({workObject, status}: WorkObjectProps) => {
    const {
        specifications: {
            ds: workSpecifications,
            vvr_current: completedWorkSpecifications,
            vvr_other: finishedWorkSpecifications,
        },
    } = workObject;
    if (!workSpecifications) return null;

    const sum = completedWorkSpecifications.reduce(
        (previousValue, currentValue) => (+currentValue.sum ?? 0) + previousValue,
        0,
    );
    const currentVatRate = +completedWorkSpecifications[0]?.rate;
    const vatSum = calcVatSum(sum, currentVatRate);
    const unclosedVatRate = workSpecifications[0]?.rate ?? 0;
    const unclosedSum = workSpecifications.reduce(
        (previousValue, currentValue) => (+currentValue.sum ?? 0) + previousValue,
        0,
    );
    const unclosedVatSum = calcVatSum(unclosedSum, unclosedVatRate);

    const totalSum = formatNumber(sum + vatSum);
    const unclosedTotalSum = formatNumber(unclosedSum + unclosedVatSum);
    const completedSpecsLength = completedWorkSpecifications.length;
    const initialSpecsLength = workSpecifications.length;
    const objectTitle = createAgreementWorkObjectName(workObject);
    return (
        <>
            <WorkObjectNameRow>
                <TableData colSpan={11}>
                    <Typography variant="subtitle3">{objectTitle}</Typography>
                </TableData>
            </WorkObjectNameRow>
            {completedWorkSpecifications.map((specification, index) => (
                <SpecificationRow
                    key={`${specification.id}${specification.result ?? ''}`}
                    specification={specification}
                    mode="current"
                    status={status}
                    index={index + 1}
                />
            ))}
            {completedSpecsLength > 0 && (
                <Summary
                    agreementOnRevision={false}
                    sums={{
                        sum: sum,
                        sum_tax: vatSum,
                        total_sum: totalSum,
                    }}
                />
            )}
            {workSpecifications.map((specification) => (
                <SpecificationRow
                    key={`${specification.id}${specification.result ?? ''}`}
                    specification={specification}
                    mode="available"
                    status={status}
                    inactive
                />
            ))}
            {initialSpecsLength > 0 && (
                <Summary
                    agreementOnRevision={false}
                    sums={{
                        sum: unclosedSum,
                        sum_tax: unclosedVatSum,
                        total_sum: unclosedTotalSum,
                    }}
                    unclosed
                />
            )}
            {finishedWorkSpecifications.map((specification) => (
                <SpecificationRow
                    key={`${specification.id}${specification.result ?? ''}_finished`}
                    specification={specification}
                    mode="closed"
                    status={status}
                    inactive
                />
            ))}
        </>
    );
};
