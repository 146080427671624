export const DRAGGING_TEXT = 'Отпустите сюда файлы для загрузки';

export const FILE_UPLOADER_TEXT = {
    defaultMainText: 'Перетащите сюда файлы или',
    defaultUploadActionText: 'загрузите документы',
};

export const MIN_FILES_COUNT_TEXT = 'Мин. кол-во файлов -';
export const MAX_FILES_COUNT_TEXT = 'Макс. кол-во файлов -';

export const SIZE_LESS_THEN = 'до';
