import React from 'react';
import {Icon, Popover} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont/icons';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {formatNumber} from 'shared/helpers/formatHelper';
import {
    calcApprovedCompletedWorksSum,
    calcTotalCompletedWorksSum,
} from 'shared/helpers/calculators';

import * as S from './style';

interface CompletedWorksCompletionCardProps {
    agreementSum: number;
    completedWorksList: {approved_at: string | null; sum: string}[];
    fromAgreement?: boolean;
}

export const CompletedWorksCompletionLabel = ({
    agreementSum,
    completedWorksList,
    fromAgreement,
}: CompletedWorksCompletionCardProps) => {
    const approvedCompletedWorksSum = calcApprovedCompletedWorksSum(completedWorksList);
    const totalCompletedWorksSum = calcTotalCompletedWorksSum(completedWorksList);
    const approvedCompletion = formatNumber((approvedCompletedWorksSum / agreementSum) * 100, 0);
    const totalCompletion = formatNumber((totalCompletedWorksSum / agreementSum) * 100, 0);
    const labelColor = approvedCompletion === 100 ? 'success' : 'warning';
    const labelTitle =
        approvedCompletion === 100 && totalCompletion === 100
            ? `100%`
            : `${approvedCompletion} / ${totalCompletion}%`;
    const iconColor = approvedCompletion === 100 ? 'green' : 'orange';
    return (
        <Popover
            content={
                <S.Wrapper>
                    <WrapperFlex alignItems="center" gap="8px">
                        <S.StyledTypography variant="body3" flex="0 0 160px">
                            По согласованным ВВР
                        </S.StyledTypography>
                        <Icon iconName={Icons.Dot} color={iconColor} />
                        <S.StyledTypography
                            variant="body3"
                            flex="0 0 30px"
                        >{`${approvedCompletion}%`}</S.StyledTypography>
                    </WrapperFlex>
                    <WrapperFlex alignItems="center" gap="8px">
                        <S.StyledTypography variant="body3" flex="0 0 160px">
                            По всем ВВР
                        </S.StyledTypography>
                        <Icon iconName={Icons.Dot} color={iconColor} />
                        <S.StyledTypography
                            variant="body3"
                            flex="0 0 30px"
                        >{`${totalCompletion}%`}</S.StyledTypography>
                    </WrapperFlex>
                </S.Wrapper>
            }
        >
            <S.StyledLabel title={labelTitle} type={labelColor} $fromAgreement={fromAgreement} />
        </Popover>
    );
};
