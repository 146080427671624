import {combine, createDomain, sample} from 'effector';

import {AdditionalAgreementFull} from 'pages/DSPage/types';
import {$isUserGpo, $vatRate} from 'shared/model/user';
import {EAdditionalAgreementType, IUpdatedAgreement} from 'shared/types/additionalAgreementsTypes';

import {
    $agreementId,
    $agreementOnRevision,
    $createAdditionalAgreementData,
    $lavParts,
    $signingDateIsEditable,
    $validConstructor,
    ConstructorGate,
    getAdditionalAgreementForConstructorFx,
    initialState,
    submitCreateAdditionalAgreement,
    updateAdditionalAgreementFx,
} from '../stores';
import {adaptAgreementToConstructor, getUpdatedAgreement} from '../../helpers';

export const updateAgreementDomain = createDomain();
export const updateAgreement = updateAgreementDomain.createEvent<AdditionalAgreementFull>();
export const createAgreementAddition = updateAgreementDomain.createEvent<AdditionalAgreementFull>();
export const setSigningDateAvailability =
    updateAgreementDomain.createEvent<AdditionalAgreementFull>();

export const $agreementBeforeUpdatesAndTransforms =
    updateAgreementDomain.createStore<IUpdatedAgreement>(initialState);
$agreementId.on(ConstructorGate.open, (state, {agreementId}) => agreementId);

// load agreement
sample({
    clock: ConstructorGate.open,
    source: $createAdditionalAgreementData,
    filter: (ds, {agreementId}) => !ds.project_id && !!agreementId,
    fn: (_, {agreementId}) => ({id: agreementId || ''}),
    target: getAdditionalAgreementForConstructorFx,
});

// save agreement to compare with updated later
sample({
    clock: updateAgreement,
    source: $vatRate,
    fn: (currentVatRate, agreement) => {
        const vatRate =
            agreement.works[0]?.work_objects[0]?.work_specifications[0]?.rate ?? currentVatRate;
        return adaptAgreementToConstructor(agreement, vatRate);
    },
    target: $agreementBeforeUpdatesAndTransforms,
});
sample({
    clock: getAdditionalAgreementForConstructorFx.doneData,
    source: $vatRate,
    fn: (vatRate, {data}) => adaptAgreementToConstructor(data, vatRate),
    target: $agreementBeforeUpdatesAndTransforms,
});

// adapt agreement to constructor
sample({
    clock: updateAgreement,
    source: $vatRate,
    fn: (vatRate, data) => adaptAgreementToConstructor(data, vatRate),
    target: [$createAdditionalAgreementData, setSigningDateAvailability],
});

sample({
    clock: createAgreementAddition,
    source: $vatRate,
    fn: (vatRate, data) =>
        data
            ? adaptAgreementToConstructor(data, vatRate, EAdditionalAgreementType.Addition)
            : initialState,
    target: [$createAdditionalAgreementData, setSigningDateAvailability],
});

sample({
    clock: getAdditionalAgreementForConstructorFx.doneData,
    source: $vatRate,
    fn: ($vatRate, {data}) => adaptAgreementToConstructor(data, $vatRate),
    target: [$createAdditionalAgreementData, setSigningDateAvailability],
});

// check if editing is allowed
sample({
    clock: [ConstructorGate.open, $isUserGpo, getAdditionalAgreementForConstructorFx.doneData],
    source: combine({
        isGpo: $isUserGpo,
        ds: $createAdditionalAgreementData,
    }),
    filter: ({ds, isGpo}) => isGpo && !!ds.id,
    fn: ({ds, isGpo}) => {
        return isGpo && ds?.status?.name === 'На доработке';
    },
    target: $agreementOnRevision,
});

// редактирование ДС
sample({
    clock: submitCreateAdditionalAgreement,
    source: combine({
        agreementId: $agreementId,
        rawNewAgreement: $createAdditionalAgreementData,
        rawOldAgreement: $agreementBeforeUpdatesAndTransforms,
        valid: $validConstructor,
        lavParts: $lavParts,
        vatRate: $vatRate,
    }),
    filter: ({valid, rawNewAgreement, agreementId}) =>
        valid && !!rawNewAgreement.status && !!agreementId,
    fn: ({rawNewAgreement, rawOldAgreement, lavParts, vatRate}, {isDraft}) => {
        rawNewAgreement.meta = isDraft ? 'save_draft' : 'send_to_approve';
        let updatedAgreement = getUpdatedAgreement(
            rawNewAgreement,
            rawOldAgreement,
            lavParts,
            vatRate,
        );
        return {data: updatedAgreement, id: rawNewAgreement.id || ''};
    },
    target: updateAdditionalAgreementFx,
});

sample({
    clock: setSigningDateAvailability,
    fn: (agreement) => agreement.number_addendum === 1 && !agreement.parent_signing_date,
    target: $signingDateIsEditable,
});
