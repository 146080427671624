import {PopoverWithCopyButton} from 'Features/PopoverWithCopyButton';
import {TableStringTCP} from 'pages/VirSelect/ui/TpiRow/style';
import {ICoefficient} from 'pages/ApplyCoefficientsPage/types';
import {TableCell, TableCellContent} from 'shared/ui/Table/Table';
import Checkbox from 'shared/ui/deprecated/Checkbox/Checkbox';
import {formatNumberToDecimalWithComma} from 'shared/helpers/formatHelper';

import * as S from './style';

interface CoefficientRowProps {
    item: ICoefficient;
    onSelected: (e: HTMLInputElement) => void;
    checked: boolean;
    isPartly?: boolean;
}

export const CoefficientRow = (props: CoefficientRowProps) => {
    const {item, onSelected, checked, isPartly = false} = props;
    return (
        <TableStringTCP checked={checked}>
            <TableCell>
                <TableCellContent>
                    <Checkbox isPartly={isPartly} checked={checked} onChange={onSelected} />
                </TableCellContent>
            </TableCell>
            <TableCell>
                <TableCellContent>{item.number}</TableCellContent>
            </TableCell>
            <TableCell>
                <TableCellContent>
                    <S.NameCell>
                        {item?.note && <PopoverWithCopyButton text={item.note} />}
                        {item.name}
                    </S.NameCell>
                </TableCellContent>
            </TableCell>
            <TableCell>
                <TableCellContent>
                    {formatNumberToDecimalWithComma(item.coefficient || 1)}
                </TableCellContent>
            </TableCell>
        </TableStringTCP>
    );
};
