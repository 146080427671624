import styled from 'styled-components/macro';

import AccordionItem from 'shared/ui/deprecated/Accordion/Accordion';

export const StyledAccordionItem = styled(AccordionItem)<{active?: boolean; noOverflow?: boolean}>`
    & > div:first-child {
        ${(p) => p.active && ' background: rgba(25, 28, 52, 0.08);'}
    }
`;

export const Wrapper = styled.div`
    max-width: 100%;
`;
