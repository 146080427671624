import React, {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import styled from 'styled-components/macro';
import {Icon} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {SpecInfo, TpiSpecification} from 'pages/VirSelect/types';
import {UpdateCurrentSpec} from 'processes/contructor/model/stores';
import {TableCell} from 'shared/ui/Table/Table';
import {Tooltip} from 'shared/ui/deprecated/Tooltip/Tooltip';
import {calcVatSum, calcTotalSum} from 'shared/helpers/calculators';
import {
    formatCurrency,
    formatNumber,
    formatNumberToDecimalWithComma,
    formattedStringToNumber,
} from 'shared/helpers/formatHelper';
import {
    AGREED_PRICE_TEXT,
    PRICE_EDITING_PROHIBITION_TITLE,
    SET_AGREED_PRICE_TEXT,
} from 'shared/constants';

import {StyledMaskedInput} from '../style';

import {$vatRate} from 'shared/model/user';

interface PriceCellProps {
    data: TpiSpecification;
    specInfo: SpecInfo;
    touched: boolean;
    updateSpecPrice: (spec: UpdateCurrentSpec) => void;
    readOnly: boolean;
}

export const PriceCell = (props: PriceCellProps) => {
    const {data, readOnly, specInfo, touched, updateSpecPrice} = props;
    const vatRate = useStore($vatRate);
    const [onFocus, setOnFocus] = useState(false);
    const [hasError, setHasError] = useState(false);
    const isAgreedPrice = data?.is_agreed_price;
    const placeholderText = 'по согл.';
    const [placeholder, setPlaceholder] = useState(placeholderText);
    const [priceValue, setPrice] = useState(
        isAgreedPrice && Number(data.price) === 0 ? '' : formatNumberToDecimalWithComma(data.price),
    );
    useEffect(() => {
        setHasError(touched && !+data.price);
    }, [touched, data.price]);
    useEffect(() => {
        setHasError(false);
    }, []);

    function handleChangePrice() {
        const newPrice = formattedStringToNumber(priceValue) || -0;
        const sum = formatNumber(newPrice * (data.coefficient || 1) * data.quantity);
        updateSpecPrice({
            ...specInfo,
            valuesToUpdate: {
                price: newPrice,
                sum,
                sum_tax: calcVatSum(sum, vatRate),
                total_sum: calcTotalSum(sum, vatRate),
            },
        });
    }

    const tooltipTitleText = readOnly
        ? PRICE_EDITING_PROHIBITION_TITLE
        : hasError
        ? SET_AGREED_PRICE_TEXT
        : AGREED_PRICE_TEXT;
    const isEditable = isAgreedPrice && !readOnly;
    return (
        <PriceEditableCell isEditable={isEditable} error={hasError} width="142px">
            <Wrapper>
                {isAgreedPrice && (
                    <PriceAccept>
                        <Tooltip
                            titleText={tooltipTitleText}
                            position="top-end"
                            horyzontal={0}
                            vertical={7}
                        >
                            <Icon size="small" iconName={Icons.Handshake} />
                        </Tooltip>
                    </PriceAccept>
                )}
                <Content isEditable={isEditable} error={hasError}>
                    {isEditable ? (
                        <PriceMaskedInput
                            error={hasError}
                            mask={'numberWithComma'}
                            value={priceValue}
                            placeholder={placeholder}
                            onFocus={() => {
                                setPlaceholder('');
                                setOnFocus(true);
                            }}
                            onBlur={() => {
                                setPlaceholder(placeholderText);
                                handleChangePrice();
                                setOnFocus(false);
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleChangePrice();
                                }
                            }}
                            onAccept={setPrice}
                        />
                    ) : (
                        <PriceText>{formatCurrency(data.price.toString())}</PriceText>
                    )}
                    {!onFocus && (
                        <>
                            {data.unit?.length >= 24 ? (
                                <Tooltip
                                    titleText={data.unit}
                                    position="top-end"
                                    horyzontal={0}
                                    vertical={7}
                                >
                                    <Unit>{data.unit}</Unit>
                                </Tooltip>
                            ) : (
                                <Unit>{data.unit}</Unit>
                            )}
                        </>
                    )}
                </Content>
            </Wrapper>
        </PriceEditableCell>
    );
};

export const PriceEditableCell = styled(TableCell)<{error?: boolean; isEditable?: boolean}>`
    padding: 0;
    ${(p) => p.error && `background:var(--red-avatar); box-shadow:inset 0 0 0 1px  var(--red);`}
`;

export const Wrapper = styled.div`
    height: 100%;
    padding: 0 0 0 16px;
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: end;
`;

export const PriceAccept = styled.div``;

export const PriceMaskedInput = styled(StyledMaskedInput)`
    & input {
        text-align: right;
        padding-right: 16px;
        padding-left: 8px;
    }
`;

export const Unit = styled.div`
    color: var(--secondary);
    padding-right: 16px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    box-orient: vertical;
    word-break: break-word;
    text-align: right;
`;

export const Content = styled.div<{isEditable: boolean; error: boolean}>`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 8px;
    justify-content: center;

    &:hover,
    &:focus-within {
        ${(p) =>
            p.isEditable &&
            !p.error &&
            `background: rgba(25, 28, 52, 0.08); box-shadow:inset 0 0 0 1px black;`};
    }
`;

export const PriceText = styled.span`
    display: inline-block;
    padding-right: 16px;
`;
