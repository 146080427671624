import React from 'react';
import {Typography} from '@beeline/design-system-react';

import {UserBadge} from 'Entities/UserBadge';
import {IComment} from 'pages/DSPage/types';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {getAvatarColorType} from 'shared/helpers/colors';

import * as S from './style';

export const Comment = (commentObj: IComment) => {
    const {creator, comment, created_at} = commentObj;
    const avatarInitials = `${creator.first_name[0] ?? ''}${creator.last_name[0] ?? ''}`;
    const color = getAvatarColorType(creator);
    return (
        <S.CommentWrapper>
            <WrapperOffset offsetTop={12} offsetBottom={12}>
                <UserBadge
                    color={color}
                    initials={avatarInitials}
                    position={creator.position}
                    shortName={creator.short}
                />
            </WrapperOffset>
            <S.StyledTypography variant="body2">{comment}</S.StyledTypography>
            <S.CommentDate>
                <Typography variant="caption">{formatDateHours(created_at.toString())}</Typography>
            </S.CommentDate>
        </S.CommentWrapper>
    );
};
