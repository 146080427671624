export enum EActionType {
    AddFiles = 'addFiles',
    ChangeFile = 'changeFile',
    UpdateFile = 'updateFile',
    ResetState = 'resetState',
    DeleteFile = 'deleteFile',
    SetError = 'setError',
    ResetError = 'resetError',
    AttachDate = 'attachDate',
}

export const FILE_ERROR_MESSAGES = {
    maxFilesCountExceeded: 'Превышен лимит загружаемых документов',
    allowedFormats: 'Недопустимый формат файла',
    maxFileSize: 'Превышен допустимый размер документа',
    hasNoRegistrationDate: 'Необходимо заполнить дату(ы) оформления файла(ов)',
    fileCountBelowRequired: 'Необходимо загрузить минимальное количество файлов',
};

export const FILE_ERROR_BASE = 'Ошибка: ';
