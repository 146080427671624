import styled from 'styled-components';

import {H5Styled} from 'shared/ui/deprecated/HeaderText/style';

export const ModalTitle = styled(H5Styled)`
    margin-bottom: 16px;
`;

export const ModalText = styled.div`
    font-size: 17px;
`;