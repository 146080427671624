import {useLocation, useNavigate} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Button, Typography} from '@beeline/design-system-react';

import {BodyContent, MainWrapper} from 'components/PageWraps';
import {DecreasingCoefficientsTab} from 'pages/ApplyCoefficientsPage/ui/DecreasingCoefficientsTab';
import {IncreasingCoefficientsTab} from 'pages/ApplyCoefficientsPage/ui/IncreasingCoefficientsTab';
import {OtherCoefficientsTab} from 'pages/ApplyCoefficientsPage/ui/OtherCoeffientsTab';
import {
    $tpiToConfigCoefficient,
    ApplyCoefficientsGate,
    coefficientsPage,
    setActiveTab,
} from 'pages/ApplyCoefficientsPage/model';
import {COEFFICIENTS_ACTIVE_TAB} from 'pages/ApplyCoefficientsPage/types';
import {SearchCoefficientsInput} from 'pages/ApplyCoefficientsPage/ui/shared/SearchCoefficientsInput';
import {$createAdditionalAgreementData} from 'processes/contructor';
import {updateSpecCoefficient} from 'processes/contructor/model/stores';
import {$vatRate} from 'shared/model/user';
import {calcVatSum, calcTotalSum} from 'shared/helpers/calculators';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {TabsBtn} from 'shared/ui/deprecated/Tabs/Tabs';
import {formatNumber} from 'shared/helpers/formatHelper';

import * as S from './styled';
import {HeaderApplyCoefficients} from './HeaderApplyCoefficients';
import {FormulaCalculationBlock} from './shared/FormulaCalculationBlock';

export const ApplyCoefficientsPage = () => {
    const location = useLocation();
    const vatRate = useStore($vatRate);
    const tpi_specification_id = location.state.tpi_specification_id as string;
    useGate(ApplyCoefficientsGate, tpi_specification_id);
    const {activeTab, selectedCoefficients, mainCoefficient} = useStore(coefficientsPage);
    const ds = useStore($createAdditionalAgreementData);
    const stateOfSpec = useStore($tpiToConfigCoefficient);
    const navigate = useNavigate();

    function returnToConstructor() {
        if (ds?.id) {
            navigate(`/additional-agreement/${ds.id}/constructor/`);
        } else {
            navigate(`/additional-agreement/constructor`);
        }
    }

    function applyNewCoefficients() {
        if (!!stateOfSpec) {
            const {price, quantity} = stateOfSpec.spec;
            const sum = formatNumber(price * quantity * (mainCoefficient || 1));
            updateSpecCoefficient({
                ...stateOfSpec,
                valuesToUpdate: {
                    coefficient: mainCoefficient,
                    work_coefficients: selectedCoefficients,
                    sum,
                    sum_tax: calcVatSum(sum, vatRate),
                    total_sum: calcTotalSum(sum, vatRate),
                },
            });
        }
        returnToConstructor();
    }

    return (
        <MainWrapper>
            <HeaderApplyCoefficients />
            <BodyContent>
                <WrapperOffset offset="32" className="w-100">
                    <S.ContentWrapper>
                        <Typography variant="h4">Применить коэффициент</Typography>
                        <S.TopBlock>
                            <SearchCoefficientsInput />
                            <FormulaCalculationBlock />
                        </S.TopBlock>
                        <S.TabPanel>
                            <TabsBtn
                                title="Повышающий"
                                value={COEFFICIENTS_ACTIVE_TAB.INCREASING}
                                activeTab={activeTab}
                                onClick={setActiveTab}
                            />
                            <TabsBtn
                                title="Понижающий"
                                value={COEFFICIENTS_ACTIVE_TAB.DECREASING}
                                activeTab={activeTab}
                                onClick={setActiveTab}
                            />
                            <TabsBtn
                                title="Другой"
                                value={COEFFICIENTS_ACTIVE_TAB.CUSTOM}
                                activeTab={activeTab}
                                onClick={setActiveTab}
                            />
                        </S.TabPanel>
                        <S.Tabs>
                            <IncreasingCoefficientsTab />
                            <DecreasingCoefficientsTab />
                            <OtherCoefficientsTab />
                        </S.Tabs>
                        <S.Buttons>
                            <Button variant="outlined" size="medium" onClick={() => navigate(-1)}>
                                Назад
                            </Button>
                            <Button
                                variant="contained"
                                size="medium"
                                onClick={applyNewCoefficients}
                            >
                                Применить
                            </Button>
                        </S.Buttons>
                    </S.ContentWrapper>
                </WrapperOffset>
            </BodyContent>
        </MainWrapper>
    );
};
