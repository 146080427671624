import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Autocomplete, Button, IconButton, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {TrimTextArea} from 'Features/TrimTextArea';
import {CommonLayout} from 'shared/ui/CommonLayout';
import {
    $documentCreationStore,
    changeCommentValue,
    changeSelectedDocumentType,
    creationButtonClicked,
    DocumentCreationGate,
    editingButtonClicked,
    resetSelectedDocumentType,
} from 'pages/DocumentCreationPage/model';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {TFile, useFileUploader} from 'shared/ui/use-file-uploader';
import {EActionType} from 'shared/ui/use-file-uploader/store';
import {TFileUploaderMode} from 'shared/ui/use-file-uploader/useFileUploader.types';
import {DOCUMENT_ALLOWED_FORMAT_LIST, MAX_ALLOWED_UPLOAD_FILE_SIZE} from 'shared/const/fileFormats';
import {
    CANCEL_BUTTON_TITLE,
    CREATE_TITLE,
    DOCUMENT_TYPE_LABEL,
    EDIT_TITLE,
    FOR_AGREEMENT_BUTTON_TITLE,
    UNNECESSARY_TEXTAREA_LABEL,
} from 'shared/constants';

import * as S from './style';

interface IDocumentCreationPageProps {
    initialFiles?: TFile[];
    mode: TFileUploaderMode;
    projId?: string;
    documentId?: string;
    subtype_document?: string;
    handleCloseEditing?: (bool: boolean) => void;
    handleClickWorkflow?: (index: number) => void;
    isWorkflowButtonClicked?: boolean;
}

export const DocumentCreationPage = ({
    initialFiles,
    mode,
    documentId,
    projId,
    handleCloseEditing,
    handleClickWorkflow,
    subtype_document,
    isWorkflowButtonClicked,
}: IDocumentCreationPageProps) => {
    const {projectId} = useParams<{projectId: string}>();
    useGate(DocumentCreationGate, projId ?? projectId);
    const {
        documentTypeList,
        selectedDocumentType,
        commentValue,
        createdDocumentId,
        editedDocumentId,
        minFilesCount,
        maxFilesCount,
        buttonClicked,
        formTouched,
    } = useStore($documentCreationStore);
    const navigate = useNavigate();
    const [FileUploader, files, fileUploaderDispatch, isError] = useFileUploader({
        fileUploaderName: 'test',
        maxFileSize: MAX_ALLOWED_UPLOAD_FILE_SIZE,
        acceptFormats: DOCUMENT_ALLOWED_FORMAT_LIST,
        minFiles: minFilesCount,
        maxFiles: maxFilesCount,
        mode,
        initialFiles,
        withRegistrationDate: selectedDocumentType?.isRegistration ?? false,
        formTouched,
    });

    const [initialState, setInitialState] = useState(files);

    if (mode === 'editing') {
        const result = subtype_document
            ? documentTypeList.find((item) => item.value === subtype_document)
            : null;

        if (result) {
            changeSelectedDocumentType(result);
        }
    }

    if (createdDocumentId) {
        navigate(`/documents/${createdDocumentId}`);
    }

    if (editedDocumentId) {
        handleCloseEditing && handleCloseEditing(false);
        handleClickWorkflow && handleClickWorkflow(0);
        navigate(`/documents/${editedDocumentId}`);
    }

    const handleCancel = () => {
        if (mode === 'creation') {
            navigate(-1);
        } else {
            handleCloseEditing && handleCloseEditing(false);
        }
    };

    const handleCreation = () => {
        if (mode === 'creation')
            creationButtonClicked({
                files,
                isError,
                isWorkflowButtonClicked,
            });
        if (mode === 'editing')
            editingButtonClicked({
                initialState,
                files,
                isError,
                projId,
                documentId,
                isWorkflowButtonClicked,
            });
    };

    const creationDisabled =
        selectedDocumentType === null ||
        (formTouched &&
            (buttonClicked ||
                isWorkflowButtonClicked ||
                Object.keys(files).length < minFilesCount ||
                isError));

    const title = mode === 'creation' ? CREATE_TITLE : EDIT_TITLE;
    useEffect(() => {
        return () => {
            fileUploaderDispatch({type: EActionType.ResetState});
        };
    }, [fileUploaderDispatch]);

    return (
        <CommonLayout>
            <WrapperOffset offset={32}>
                <S.Wrapper>
                    <S.Title>
                        <IconButton
                            iconName={Icons.ArrowLeft}
                            size="medium"
                            onClick={handleCancel}
                        />
                        <Typography variant="h4">{title} документ</Typography>
                    </S.Title>
                    <Autocomplete
                        dropdownClassName="normalWhiteSpace"
                        renderValue={(value) => value.value.toString()}
                        onInputClear={resetSelectedDocumentType}
                        onInputChange={(_) => 1}
                        makeOption={(option, inputValue) => {
                            if (inputValue) {
                                return option.value
                                    .toString()
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase()) ? (
                                    <Typography>{option.value}</Typography>
                                ) : null;
                            }
                            return <Typography>{option.value}</Typography>;
                        }}
                        onChange={changeSelectedDocumentType}
                        value={selectedDocumentType}
                        options={documentTypeList}
                        placeholder={DOCUMENT_TYPE_LABEL}
                        type="search"
                        disabled={mode === 'editing'}
                        fullWidth
                    />
                    <FileUploader />
                    <TrimTextArea
                        value={commentValue}
                        label={UNNECESSARY_TEXTAREA_LABEL}
                        onChange={changeCommentValue}
                        fullWidth
                    />
                    <S.ButtonsContainer>
                        <Button size="medium" onClick={handleCancel} disabled={buttonClicked}>
                            {CANCEL_BUTTON_TITLE}
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            disabled={creationDisabled}
                            onClick={handleCreation}
                        >
                            {FOR_AGREEMENT_BUTTON_TITLE}
                        </Button>
                    </S.ButtonsContainer>
                </S.Wrapper>
            </WrapperOffset>
        </CommonLayout>
    );
};
