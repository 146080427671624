import React, {useState} from 'react';
import {IconButton, PopoverHelper} from '@beeline/design-system-react';
import {sizeSpacingX2} from '@beeline/design-tokens/js/tokens';
import {Icons} from '@beeline/design-tokens/js/iconfont/icons';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {useOutsideClick} from 'shared/hooks/useOutsideClick';
import {copyToClipboard} from 'shared/helpers/commonFunctions';
import {FormattedTypography} from 'shared/styles/commonStyle';

import * as S from './style';

interface PopoverWithCopyButtonProps {
    text: string;
    className?: string;
}

export const PopoverWithCopyButton = ({className, text}: PopoverWithCopyButtonProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => {
        setIsOpen((prevState) => !prevState);
    };
    const ref = useOutsideClick<HTMLDivElement>(toggleIsOpen);
    return (
        <PopoverHelper
            body={
                <WrapperFlex flexDirection="column" justifyContent="flex-start" gap={sizeSpacingX2}>
                    <FormattedTypography variant="body3">{text}</FormattedTypography>
                    <S.StyledButton
                        variant="plain"
                        onClick={() => copyToClipboard(text)}
                        className={className}
                    >
                        Сохранить
                    </S.StyledButton>
                </WrapperFlex>
            }
            open={isOpen}
            ref={ref}
            disableHover
        >
            <IconButton iconName={Icons.InfoCircled} onClick={toggleIsOpen} size="large" />
        </PopoverHelper>
    );
};
