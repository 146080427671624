import React from 'react';

import {InformationalUnitSkeleton} from 'Entities/InformationalUnit';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';

import * as S from './style';

export function DSPageInfoSkeleton() {
    return (
        <WrapperOffset offsetTop={36}>
            <S.GridSkeleton height={28} width={270} variant="text" />
            <S.GridWrapper>
                <InformationalUnitSkeleton gridArea="address" />
                <InformationalUnitSkeleton gridArea="creationDate" />
                <InformationalUnitSkeleton gridArea="approvingDate" />
                <InformationalUnitSkeleton gridArea="jobType" />
                <InformationalUnitSkeleton gridArea="projectType" />
                <InformationalUnitSkeleton gridArea="" />
                <InformationalUnitSkeleton gridArea="contractor" />
                <InformationalUnitSkeleton gridArea="purchase" />
                <InformationalUnitSkeleton gridArea="completedWorksCompletion" />
            </S.GridWrapper>
        </WrapperOffset>
    );
}
