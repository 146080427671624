import styled, {css} from 'styled-components/macro';
import {Label, Typography} from '@beeline/design-system-react';

export const Wrapper = styled.div`
    width: 230px;
    margin: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledTypography = styled(Typography)<{flex: string}>`
    ${(props) =>
        props.flex &&
        css`
            flex: ${props.flex};
        `}
`;

export const StyledLabel = styled(Label)<{$fromAgreement?: boolean}>`
    ${(props) =>
        props.$fromAgreement &&
        css`
            margin-top: var(--size-spacing-x1);
        `}
`;
