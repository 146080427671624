import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Button, Skeleton, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {createAgreementAddition, resetAdditionalAgreementCreation} from 'processes/contructor';
import {EmptyTab} from 'Entities/EmptyTab';
import {$userActions} from 'shared/model/user';
import {Menu} from 'shared/ui/Menu';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';

import {AdditionalAgreementsTable} from './AgreementsTable';
import {columns, innerColumns} from './allColumns';
import * as S from './style';
import {
    $additionalAgreementsTable,
    AdditionalAgreementsGate,
    createAddAgreementAddition,
} from '../model';
import {VerticalTabBlock} from '../../ProjectViewVerticalTabs/ProjectViewVerticalTabs';
import {selectProject} from '../../../../VirStart/model';

interface AdditionalAgreementsTabProps {
    activeTab: number;
    projectId?: string;
    projectNumber?: number;
}

export const AdditionalAgreementsTab = ({
    activeTab,
    projectNumber,
    projectId,
}: AdditionalAgreementsTabProps) => {
    useGate(AdditionalAgreementsGate, {projectId: projectId || ''});
    const navigate = useNavigate();
    const {copyingAgreementList, isLoadingAddAgreementCopy, agreementCopy, isEmpty} = useStore(
        $additionalAgreementsTable,
    );
    const verticalTab = 4;
    const userActions = useStore($userActions);
    const creationIsAllowed = userHasPermission(EAccessActions.AgreementCreate, userActions);
    const projectViewPageIsLoading = !projectNumber;

    if (agreementCopy) {
        createAgreementAddition(agreementCopy);
        navigate(`/additional-agreement/${agreementCopy.id}/constructor`);
    }

    const menuOptions = copyingAgreementList.map((item) => ({
        item: <Typography variant="body2"> К ДС {item.number_agreement}</Typography>,
        action: () => createAddAgreementAddition(item.id),
    }));

    const onClickCreateAgreement = () => {
        {
            if (projectNumber && projectId) {
                resetAdditionalAgreementCreation();
                selectProject({
                    project_id: projectId,
                    project_number: projectNumber.toString(),
                });
                navigate(`/projects/${projectId}/additional-agreement/create`);
            }
        }
    };

    return (
        <VerticalTabBlock data-value={verticalTab} activeTab={activeTab}>
            <S.Wrapper>
                <Typography variant="h5">Дополнительные соглашения</Typography>
                {!isEmpty ? (
                    <>
                        {creationIsAllowed && (
                            <S.ActionUnit>
                                {projectViewPageIsLoading ? (
                                    <Skeleton variant="square" width="103px" height="48px" />
                                ) : (
                                    <Button
                                        id="agreementCreationBtn"
                                        color="outline"
                                        size="medium"
                                        onClick={onClickCreateAgreement}
                                    >
                                        Создать
                                    </Button>
                                )}
                                {isLoadingAddAgreementCopy ? (
                                    <Skeleton variant="square" width="196px" height="48px" />
                                ) : (
                                    <Menu
                                        width="430px"
                                        title="Создать дополнение"
                                        disabled={
                                            menuOptions.length === 0 || isLoadingAddAgreementCopy
                                        }
                                        options={menuOptions}
                                        maxHeight
                                    />
                                )}
                            </S.ActionUnit>
                        )}
                        <AdditionalAgreementsTable columns={columns} innerColumns={innerColumns} />
                    </>
                ) : (
                    <EmptyTab
                        iconName={Icons.PageEmpty}
                        color="blue"
                        showButton={creationIsAllowed}
                        title="Доп. соглашений еще нет"
                        subtitle="Вы можете создать его, нажав на кнопку ниже"
                        buttonTitle="Создать доп. соглашение"
                        onClick={onClickCreateAgreement}
                    />
                )}
            </S.Wrapper>
        </VerticalTabBlock>
    );
};
