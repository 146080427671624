import {TpiSpecification} from 'pages/VirSelect/types';
import {calcTotalSum, calcVatSum} from 'shared/helpers/calculators';
import {formatNumber} from 'shared/helpers/formatHelper';

import {getVirWorkObjects} from './getters';
import {GetVir} from './types';

export function calcSumForOneSpecification(item: TpiSpecification) {
    return formatNumber(
        (item.price >= 0 ? item.price : 0) * (item?.coefficient || 1) * (item.quantity || 1),
    );
}

export function calsSumForSpecs(specs: TpiSpecification[]) {
    return specs.reduce((acc, curr) => acc + calcSumForOneSpecification(curr), 0);
}

export function getCalculatedSpecsWithDefaultQuantity(
    items: TpiSpecification[],
    vatRate: number | null,
) {
    return items?.reduce(
        (acc, curr) => ({
            ...acc,
            [`${curr.id}`]: {
                ...curr,
                sum: calcSumForOneSpecification(curr),
                sum_tax: calcVatSum(calcSumForOneSpecification(curr), vatRate),
                total_sum: calcTotalSum(calcSumForOneSpecification(curr), vatRate),
                quantity: curr.quantity || 1,
            },
        }),
        {},
    );
}

export const calculateVirTotals = (
    {ds, virId}: GetVir,
    vatRate: number | null,
): {
    sum: number;
    sum_tax: number;
    total_sum: number;
} => {
    const workObjects = getVirWorkObjects({ds, virId});

    const sum = (Object.values(workObjects) || [])
        ?.map((el) => Object?.values(el?.work_specifications || [])?.map((el) => el?.sum || 0))
        ?.flat()
        .reduce((acc, curr) => acc + curr, 0 as number);
    const sum_tax = calcVatSum(sum, vatRate);
    return {
        sum,
        sum_tax,
        total_sum: calcTotalSum(sum, vatRate),
    };
};

export function calculateWorkObjectTotalsBySpecs(
    {specs}: {specs: TpiSpecification[]},
    vatRate: number | null,
): {
    sum: number;
    sum_tax: number;
    total_sum: number;
} {
    const sum = calsSumForSpecs(specs);
    const sum_tax = specs.reduce((sum, item) => {
        return (
            sum +
            (item.is_tax || item.sum_tax > 0
                ? calcVatSum(calcSumForOneSpecification(item), vatRate)
                : 0)
        );
    }, 0);
    return {
        sum,
        sum_tax,
        total_sum: formatNumber(sum + sum_tax),
    };
}
