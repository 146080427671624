import type {ChangeEvent, DragEvent} from 'react';
import {useEffect, useState} from 'react';

import {EMPTY_DATA_OBJ, EMPTY_NUMBERED_VALUE} from './constants';

import {resetError, setError, uploadFiles} from './store';
import type {TDragAndDropControlsHook, TFile} from './useFileUploader.types';
import {filterFilesByAllowedFormats, filterFilesByMaxFileSize} from './utils';
import {
    attachRegistrationDate,
    updateFile,
} from 'shared/ui/use-file-uploader/store/fileUploaderWorkers';

export const useDragAndDropControls: TDragAndDropControlsHook = (props) => {
    const {
        uploadAvailableFilesCount,
        acceptFormats,
        maxFileSize,
        fileUploaderDispatch,
        error,
        registrationDateError,
        minFileCountError,
    } = props;

    const [isDragging, setDragging] = useState(false);
    useEffect(() => {
        if ((uploadAvailableFilesCount ?? -1) >= 0) {
            resetError({fileUploaderDispatch});
        }
        if ((uploadAvailableFilesCount ?? 1) < 0) {
            setError({
                fileUploaderDispatch,
                errorFactors: {
                    isFilteredByAllowedFormats: false,
                    isFilteredByMaxFileSize: false,
                    isCroppedFilesCount: true,
                    isEmptyRegistrationDate: false,
                    isFileCountTooLow: false,
                },
            });
        }
        if (registrationDateError) {
            setError({
                fileUploaderDispatch,
                errorFactors: {
                    isFilteredByAllowedFormats: false,
                    isFilteredByMaxFileSize: false,
                    isCroppedFilesCount: false,
                    isEmptyRegistrationDate: true,
                    isFileCountTooLow: false,
                },
            });
        }
        if (minFileCountError) {
            setError({
                fileUploaderDispatch,
                errorFactors: {
                    isFilteredByAllowedFormats: false,
                    isFilteredByMaxFileSize: false,
                    isCroppedFilesCount: false,
                    isEmptyRegistrationDate: false,
                    isFileCountTooLow: true,
                },
            });
        }
    }, [uploadAvailableFilesCount, registrationDateError, minFileCountError]);

    const onDragOverHandler = (event: DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
    };

    const onDragLeaveHandler = (event: DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        setDragging(false);
    };

    const onDropFileHandler = (
        event: DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>,
    ): void => {
        event.preventDefault();
        setDragging(false);
        if (error) {
            resetError({fileUploaderDispatch});
        }

        const {dataTransfer} = (event as DragEvent<HTMLDivElement>) || EMPTY_DATA_OBJ;
        const {files} = dataTransfer || event.target;

        const {
            filteredFiles: filesFilteredByAllowedFormats,
            isAnyFileFiltered: isFilteredByAllowedFormats,
        } = filterFilesByAllowedFormats(files, acceptFormats);
        const {
            filteredFiles: filesFilteredByAFAndMaxFileSize,
            isAnyFileFiltered: isFilteredByMaxFileSize,
        } = filterFilesByMaxFileSize(filesFilteredByAllowedFormats, maxFileSize);
        const filesAllowedAfterAllChecks = filesFilteredByAFAndMaxFileSize;

        const isMaxFilesRestrictionAvailable = uploadAvailableFilesCount !== null;
        const isMustCropFilesPack =
            isMaxFilesRestrictionAvailable &&
            filesAllowedAfterAllChecks.length > uploadAvailableFilesCount;

        const filesAllowedForUpload = isMustCropFilesPack
            ? [...filesAllowedAfterAllChecks].slice(EMPTY_NUMBERED_VALUE, uploadAvailableFilesCount)
            : filesAllowedAfterAllChecks;

        setError({
            fileUploaderDispatch,
            errorFactors: {
                isFilteredByAllowedFormats,
                isFilteredByMaxFileSize,
                isCroppedFilesCount: isMustCropFilesPack,
                isEmptyRegistrationDate: false,
                isFileCountTooLow: false,
            },
        });

        const hasFilesToUpload = filesAllowedForUpload.length > EMPTY_NUMBERED_VALUE;
        if (hasFilesToUpload) {
            uploadFiles({files: filesAllowedForUpload, fileUploaderDispatch});
        }
    };

    const onChangeFileHandler = (
        oldFile: TFile,
        event: DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>,
    ): void => {
        event.preventDefault();
        setDragging(false);
        if (error) {
            resetError({fileUploaderDispatch});
        }

        const {dataTransfer} = (event as DragEvent<HTMLDivElement>) || EMPTY_DATA_OBJ;
        const {files} = dataTransfer || event.target;
        const {
            filteredFiles: filesFilteredByAllowedFormats,
            isAnyFileFiltered: isFilteredByAllowedFormats,
        } = filterFilesByAllowedFormats(files, acceptFormats);
        const {
            filteredFiles: filesFilteredByAFAndMaxFileSize,
            isAnyFileFiltered: isFilteredByMaxFileSize,
        } = filterFilesByMaxFileSize(filesFilteredByAllowedFormats, maxFileSize);
        const filesAllowedAfterAllChecks = filesFilteredByAFAndMaxFileSize;
        const filesAllowedForUpload = filesAllowedAfterAllChecks;

        setError({
            fileUploaderDispatch,
            errorFactors: {
                isFilteredByAllowedFormats,
                isFilteredByMaxFileSize,
                isCroppedFilesCount: false,
                isEmptyRegistrationDate: false,
                isFileCountTooLow: false,
            },
        });
        const hasFilesToUpload = filesAllowedForUpload.length > EMPTY_NUMBERED_VALUE;
        if (hasFilesToUpload) {
            updateFile({oldFile: oldFile, newFile: filesAllowedForUpload[0], fileUploaderDispatch});
        }
    };

    const onChangeRegistrationDateHandler = (file: TFile, date: string): void => {
        attachRegistrationDate({file, date, fileUploaderDispatch});
    };

    return {
        isDragging,
        onDragLeaveHandler,
        onDragOverHandler,
        onDropFileHandler,
        onChangeFileHandler,
        onChangeRegistrationDateHandler,
    };
};
