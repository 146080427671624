import styled, {css} from 'styled-components';
import {Skeleton} from '@beeline/design-system-react';

export const InfoCaption = styled.div`
    font-size: 17px;
    line-height: calc(22 / 17);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const GridWrapper = styled.div`
    margin-top: var(--size-spacing-x4);
    display: grid;
    gap: var(--size-spacing-x6);
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
        'address address creationDate approvingDate'
        'jobType projectType contractor agreement'
        'purchase purchase completedWorksCompletion completedWorksCompletion';
`;

export const GridSkeleton = styled(Skeleton)<{$gridArea?: string}>`
    ${(props) =>
        props.$gridArea &&
        css`
            grid-area: ${props.$gridArea};
        `}
`;
