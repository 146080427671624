import styled from "styled-components";

export const NameCell = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: revert;
    align-items: center;
    margin-right: 30px;
    gap: var(--size-spacing-x2);
`;
