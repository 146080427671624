import {v4 as uuidv4} from 'uuid';
import {combine, sample} from 'effector';

import {$selectedWorkObject, $selectedWorkType} from 'pages/VirStart/model';
import {$selectedTpis, SelectedTpisGate} from 'pages/VirSelect/model/SelectedTpisTab';
import {GetWorkObjectsRequest} from 'pages/AddWorkObjects/types';
import {$vatRate} from 'shared/model/user';
import {forwardPayload} from 'shared/helpers/effector';
import {addLabelToWorkOptionBasedOnType} from 'shared/helpers/formatHelper';

import {
    $checkedSpecificationsForUpdate,
    $createAdditionalAgreementData,
    $currentLocationToAddTpis,
    $jobCategoriesList,
    $lavParts,
    addWork,
    checkSpecificationToUpdate,
    ConstructorGate,
    fetchJobCategoriesListFx,
    loadWorkObjectsFx,
    resetSelectedTpis,
    saveCurrentWorkObjectToAddTpis,
    uncheckSpecificationToUpdate,
} from './stores';
import {
    calculateWorkObjectTotalsBySpecs,
    extractWorkObjects,
    getCalculatedSpecsWithDefaultQuantity,
    getVir,
    getVirs,
    getVirWorkObject,
    getVirWorkObjects,
    getWorkObjectSpecs,
    setUpdateToVir,
} from '../helpers';

$currentLocationToAddTpis.on(saveCurrentWorkObjectToAddTpis, forwardPayload());

$currentLocationToAddTpis
    .on(saveCurrentWorkObjectToAddTpis, forwardPayload())
    .reset(resetSelectedTpis);

$selectedTpis.reset(resetSelectedTpis);

export const tpisFullInfoLoaded = sample({
    clock: addWork,
    source: combine({
        data: $createAdditionalAgreementData,
        workType: $selectedWorkType,
        workObject: $selectedWorkObject,
        specs: $selectedTpis,
        existingObjectToAdd: $currentLocationToAddTpis,
    }),
    fn: (store) => {
        return {
            ...store,
            virId: store?.existingObjectToAdd?.virId || '',
            specs: store.specs,
        };
    },
});
sample({
    clock: tpisFullInfoLoaded,
    source: $vatRate,
    fn: (vatRate, {data, workType, workObject, specs, existingObjectToAdd}) => {
        const ds = {...data};
        let virId;
        let existingWorkObjectId;
        let work_objects;
        if (existingObjectToAdd) {
            virId = existingObjectToAdd.virId;
            existingWorkObjectId = existingObjectToAdd.workObjectId;
        }
        if (workType && workObject && !existingWorkObjectId) {
            virId = `front -${uuidv4()}`;
            work_objects = extractWorkObjects({
                workObject,
                workType,
                specs,
                virId: virId,
                vatRate,
            });
        }

        const sums = calculateWorkObjectTotalsBySpecs(
            {
                specs,
            },
            vatRate,
        );

        const calculatedSpecs = specs.map((el) => ({
            ...el,
            virId,
            workObjectId: existingWorkObjectId,
        }));
        const updatedWorks = {
            ...getVirs({ds}),
            [virId]: {
                ...getVir({ds, virId}),
                id: virId,
                ...sums,
                work_objects: {
                    ...getVirWorkObjects({ds, virId}),
                    ...(existingWorkObjectId
                        ? {
                              [existingWorkObjectId]: {
                                  ...getVirWorkObject({
                                      ds,
                                      virId,
                                      workObjectId: existingWorkObjectId,
                                  }),
                                  work_specifications: {
                                      ...getCalculatedSpecsWithDefaultQuantity(
                                          calculatedSpecs,
                                          vatRate,
                                      ),
                                  },
                              },
                          }
                        : work_objects),
                },
            },
        };
        return setUpdateToVir({ds, updatedWorks});
    },
    target: [$createAdditionalAgreementData, resetSelectedTpis],
});

sample({
    clock: SelectedTpisGate.open,
    source: combine({ds: $createAdditionalAgreementData, location: $currentLocationToAddTpis}),
    fn: ({ds, location}) => {
        if (!location?.workObjectId || !location?.virId) return [];
        return Object.values(
            getWorkObjectSpecs({
                ds,
                virId: location.virId,
                workObjectId: location.workObjectId,
            }),
        );
    },
    target: $selectedTpis,
});

$checkedSpecificationsForUpdate
    .on(checkSpecificationToUpdate, (state, spec) => [...state, spec])
    .on(uncheckSpecificationToUpdate, (state, specId) => state.filter((el) => el === specId));

sample({
    clock: ConstructorGate.open,
    target: fetchJobCategoriesListFx,
});
sample({
    source: combine({
        data: $createAdditionalAgreementData,
        gate: ConstructorGate.status,
        lavParts: $lavParts,
        isLoading: loadWorkObjectsFx.pending,
    }),
    filter: ({data, lavParts, isLoading}) =>
        !isLoading && !!data.project_id && Object.values(lavParts).length === 0,
    fn: ({data}) => ({project_id: data.project_id} as GetWorkObjectsRequest),
    target: loadWorkObjectsFx,
});

$lavParts
    .on(loadWorkObjectsFx.doneData, (_, payload) => {
        const result: Record<string, string[]> = {};
        const response = addLabelToWorkOptionBasedOnType(payload.data);
        response.forEach((workObj) => {
            if ((workObj.lavParts?.length ?? -1) > 0) {
                result[workObj.gfk ?? 'error'] =
                    workObj.lavParts?.map((lavPart) => lavPart.gfk) ?? [];
            }
        });
        if (Object.keys(result).length === 0) {
            result.noLav = [];
        }
        return result;
    })
    .reset(ConstructorGate.close);

$jobCategoriesList.on(fetchJobCategoriesListFx.doneData, forwardPayload());
