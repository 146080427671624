import React from 'react';
import {Skeleton} from '@beeline/design-system-react';
import {sizeSpacingX4} from '@beeline/design-tokens/js/tokens';

import Breadcrumb from 'shared/ui/deprecated/Breadcrumb/Breadcrumb';
import BreadcrumbItem from 'shared/ui/deprecated/Breadcrumb/BreadcrumbItem';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';

export function DSPageTitleSkeleton() {
    return (
        <WrapperFlex justifyContent="space-between" alignItems="flex-start" gap={sizeSpacingX4}>
            <div>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Skeleton width={70} height={18} variant="text" />
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Skeleton width={70} height={18} variant="text" />
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Skeleton width={70} height={18} variant="text" />
                    </BreadcrumbItem>
                </Breadcrumb>
                <WrapperOffset offsetTop={12}>
                    <Skeleton height={32} width={320} variant="text" />
                </WrapperOffset>
                <WrapperOffset offsetTop={12}>
                    <WrapperFlex alignItems="center">
                        <Skeleton height={16} width={360} variant="text" />
                    </WrapperFlex>
                </WrapperOffset>
            </div>
            <WrapperFlex alignItems="center" gap={sizeSpacingX4}>
                <Skeleton height={48} width={109} variant="square" />
                <Skeleton height={48} width={187} variant="square" />
            </WrapperFlex>
        </WrapperFlex>
    );
}
