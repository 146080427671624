import {Pagination} from '../../../types';

import {IComment} from 'pages/DSPage/types';
import {WorkCoefficientType} from 'shared/types/additionalAgreementsTypes';
import {TAttachmentFile} from 'shared/types/FileTypes';

export enum SELECT_TPIS_ACTIVE_TAB {
    ALL_TPIS = 1,
    SELECTED_TPIS = 2,
}

export interface GetTpiSpecificationRequest extends Pagination {
    project_id: string;
    tpi_id: string;
    remote_territory_id?: string;
    is_job_tasks?: boolean;
}

export interface GetTpiSpecificationResponse {
    data: {
        count: number;
        results: TpiSpecification[];
    };
}

export interface SpecInfo {
    spec: TpiSpecification;
    virId: string;
    workObjectId: string;
}

export interface TpiSpecification {
    id: string;
    accuracy: number;
    number: string;
    name: string;
    unit: string;
    price: number;
    note: string;
    sort_order?: string;
    workObjectId?: string;
    category_name: string | null;
    coefficient: number; // TODO can be changed
    coefficient_remote_territory: number;
    coefficient_ams: number;
    coefficient_foundation: number;
    is_ams: boolean;
    is_category: boolean;
    is_foundation: boolean;
    is_agreed_price: boolean;
    is_tax: boolean;
    begin_date: string | null; // TODO can be changed
    end_date: string | null; // TODO can be changed
    default_begin_date: string | null;
    default_end_date: string | null;
    is_confirmed?: boolean;
    selected_id?: string;
    quantity: number; // TODO can be changed
    tpi_specification_id: string;
    ordinal_number: number;
    is_prepayment: boolean;
    is_price_editable?: boolean;
    is_quantity_editable?: boolean;
    is_ws_deletable?: boolean;
    master_position?: string;
    rate?: number;
    sum: number;
    sum_tax: number;
    total_sum: number;
    tpi_specification: string;
    tax_rate: string;
    type_position: number;
    work_detail: {id: string; work_category: string} | null;
    work_type_dative_case?: string;
    work_type_id?: string;
    work_type_name?: string;
    work_object_id?: string;
    work_object?: string;
    virId?: string;
    update_action?: 'new' | 'update' | 'delete' | 'nothing';
    work_specification_approval_total?: boolean;
    work_specification_approval?: boolean;
    work_coefficients: WorkCoefficientType[];
    work_specification_comments?: Array<Partial<IComment> & Pick<IComment, 'comment'>>;
    work_specification_attachments?: TAttachmentFile[];
    job_task_coefficients?: [];
    work_category_id?: string;
}
