import React from 'react';
import {useParams} from 'react-router-dom';
import {useGate, useUnit} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {BodyContent, MainWrapper} from 'components/PageWraps';
import {VirCreatePageGate} from 'pages/VirStart/model';
import {$agreementType, $createAdditionalAgreementData} from 'processes/contructor';
import {VatErrorWidget} from 'widgets/VatErrorWidget/';
import {$vatRate} from 'shared/model/user';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {TAX_SETTINGS_ARE_NOT_FOUND_TITLE} from 'shared/constants';

import {GlobalStyles, VirStartBlock} from './styled';
import {VIRData} from './VirStartData';
import {ActionWrap} from './VirStartActionWrap/VirStartActionWrap';
import {AgreementConstructorHeader} from '../../AddWorkObjects/ui/Header';

export function CreateVIRStart() {
    const {projectId} = useParams();
    useGate(VirCreatePageGate, {projectId});
    const [ds, agreementMode, vatRate] = useUnit([
        $createAdditionalAgreementData,
        $agreementType,
        $vatRate,
    ]);

    return (
        <>
            <GlobalStyles />
            <MainWrapper>
                <AgreementConstructorHeader agreement={ds} agreementType={agreementMode} />
                <BodyContent>
                    <WrapperOffset offset="32" className="w-100">
                        {vatRate === null ? (
                            <VatErrorWidget
                                errorText={TAX_SETTINGS_ARE_NOT_FOUND_TITLE}
                                onClose={() => navigationInvoked({to: -1})}
                                hasLink
                            />
                        ) : (
                            <VirStartBlock>
                                <VIRData />
                                <Typography variant="h4">
                                    Чтобы упростить формирование ВИР, выберите одно из действий ниже
                                </Typography>
                                <Typography variant="h6">
                                    Далее они тоже будут вам доступны
                                </Typography>
                                <ActionWrap />
                            </VirStartBlock>
                        )}
                    </WrapperOffset>
                </BodyContent>
            </MainWrapper>
        </>
    );
}
