import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGate, useUnit} from 'effector-react';
import {Banner, Progress} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {ConstructorAttachmentUnit} from 'widgets/ConstructorAttachmentUnit';
import {VatErrorWidget} from 'widgets/VatErrorWidget';
import {ActionButtonsUnit} from 'Features/ActionButtonsUnit';
import {$vatRate} from 'shared/model/user';
import {TAX_SETTINGS_ARE_NOT_FOUND_TITLE} from 'shared/constants';

import {AgreementAccordion} from './AgreementAccordion';
import {Header} from './Header/Header';
import * as S from './style';
import {
    $completedWorksStage2Store,
    commonAttachmentsChanged,
    commonCommentChanged,
    CompletedWorksStage2Gate,
    creationBtnClicked,
} from '../../model/stage2';
import {handleDownloadAttachment, TMode} from '../../model/helpers';

interface Stage2Props {
    mode: TMode;
}

export const Stage2 = ({mode}: Stage2Props) => {
    useGate(CompletedWorksStage2Gate, mode);
    const [
        {
            agreement,
            createdCompletedWorksId,
            touchedForm,
            error,
            viewPageRedirection,
            saveButtonDisabled,
            loadingAttachmentId,
            currentCompletion,
            prevCompletion,
        },
        vatRate,
    ] = useUnit([$completedWorksStage2Store, $vatRate]);
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        if (createdCompletedWorksId) {
            navigate(`/completed-works/${createdCompletedWorksId}`);
        }
        if (viewPageRedirection) {
            navigate(`/completed-works/${id}`);
        }
    }, [createdCompletedWorksId, viewPageRedirection]);

    if (!agreement)
        return (
            <S.WindowWrapper>
                <Progress shape="circle" type="solo" value={75} cycled />
            </S.WindowWrapper>
        );

    const {completed_works_id, completed_work_comment, completed_work_attachments, ds_attachments} =
        agreement;

    const negativeBtnLabel = mode === 'creation' ? 'Сохранить черновик' : 'Сохранить изменения';
    return (
        <S.OverallWrapper>
            {vatRate === null ? (
                <VatErrorWidget
                    errorText={TAX_SETTINGS_ARE_NOT_FOUND_TITLE}
                    onClose={() => navigationInvoked({to: -1})}
                    hasLink
                />
            ) : (
                <>
                    <Header
                        agreement={agreement}
                        currentCompletion={currentCompletion}
                        prevCompletion={prevCompletion}
                    />
                    <S.BannerWrapper>
                        {touchedForm && error && (
                            <Banner title={error} color={'error'} iconName={Icons.InfoCircled} />
                        )}
                    </S.BannerWrapper>
                    {agreement && <AgreementAccordion works={agreement.works} />}
                    <ConstructorAttachmentUnit
                        id={completed_works_id}
                        loadingAttachmentId={loadingAttachmentId}
                        attachments={completed_work_attachments}
                        parentAttachments={ds_attachments}
                        comment={completed_work_comment ?? undefined}
                        onChangeComment={commonCommentChanged}
                        onChangeAttachments={commonAttachmentsChanged}
                        onDownloadParentAttachments={handleDownloadAttachment}
                    />
                    <ActionButtonsUnit
                        negativeBtnLabel={negativeBtnLabel}
                        positiveBtnLabel="Отправить на согласование"
                        handleNegativeBtn={() => creationBtnClicked('save_draft')}
                        handlePositiveBtn={() => creationBtnClicked('send_to_approve')}
                        alignSelf="flex-end"
                        hidePositiveBtn={mode === 'editing'}
                        disabledPositiveBtn={saveButtonDisabled}
                        disableAllButtons={(touchedForm && error !== null) || saveButtonDisabled}
                    />
                </>
            )}
        </S.OverallWrapper>
    );
};
