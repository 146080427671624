import {AdditionalAgreementFull} from 'pages/DSPage/types';
import {createAgreementWorkObjectName} from 'shared/helpers/additionalAgreements';
import {calcTotalSum, calcVatSum} from 'shared/helpers/calculators';
import {
    ConstructorVirType,
    EAdditionalAgreementType,
    EWorkObjectTypes,
    IUpdatedAgreement,
} from 'shared/types/additionalAgreementsTypes';

export function adaptAgreementToConstructor(
    additionalAgreementData: AdditionalAgreementFull,
    vatRate: number | null,
    mode: EAdditionalAgreementType = EAdditionalAgreementType.Agreement,
): IUpdatedAgreement {
    const isAdditionMode = mode === EAdditionalAgreementType.Addition;
    const works: Record<string, ConstructorVirType> = additionalAgreementData.works
        ? additionalAgreementData.works?.reduce((acc, vir) => {
              return {
                  ...acc,
                  [`${vir.id}`]: {
                      ...vir,
                      sum: +vir.sum,
                      sum_tax: calcVatSum(+vir.sum, vatRate),
                      total_sum: calcTotalSum(+vir.sum, vatRate),
                      work_objects: vir.work_objects?.reduce(
                          (acc, workObject) => ({
                              ...acc,
                              [workObject.id]: {
                                  id: workObject.id,
                                  work_type_id: workObject.work_type_id,
                                  object_type: workObject.object_type,
                                  transport_net_id:
                                      workObject.object_type === EWorkObjectTypes.RESPONSE_PART
                                          ? workObject.transport_net_id
                                          : undefined,
                                  is_half_set: workObject.is_half_set,
                                  lav_gfk:
                                      workObject.lav_gfk !== '' ? workObject.lav_gfk : undefined,
                                  virId: vir.id,
                                  object_id: workObject.id,
                                  object_name: createAgreementWorkObjectName(workObject),
                                  work_specifications: workObject.work_specifications?.reduce(
                                      (acc, curr) => ({
                                          ...acc,
                                          [curr.id]: {
                                              ...curr,
                                              quantity: +curr.quantity,
                                              rate: vatRate,
                                              sum: +curr.sum,
                                              sum_tax: calcVatSum(+curr.sum, vatRate),
                                              total_sum: calcTotalSum(+curr.sum, vatRate),
                                              default_begin_date: curr.begin_date,
                                              default_end_date: curr.end_date,
                                              virId: vir.id,
                                              selected_id: curr.id,
                                              tpi_specification_id: curr.tpi_specification,
                                              work_specification_attachments:
                                                  curr.work_specification_attachments || [],
                                              work_category_id: curr.work_detail?.id,
                                          },
                                      }),
                                      {},
                                  ),
                              },
                          }),
                          {},
                      ),
                  },
              };
          }, {})
        : {};
    return {
        agreement_attachments: additionalAgreementData?.agreement_attachments || [],
        agreement_comments: [],
        agreement_id: isAdditionMode
            ? additionalAgreementData.agreement_id || ''
            : additionalAgreementData?.agreement_id || '',
        id: isAdditionMode ? undefined : additionalAgreementData.id,
        agreement_number: additionalAgreementData?.agreement_number,
        begin_date: additionalAgreementData.begin_date,
        contract_id: additionalAgreementData?.contract,
        contract_name: additionalAgreementData?.contract_name,
        default_begin_date: additionalAgreementData.begin_date,
        default_end_date: additionalAgreementData?.end_date,
        end_date: additionalAgreementData?.end_date,
        is_prepayment: additionalAgreementData.is_prepayment,
        is_released: additionalAgreementData.is_released,
        is_termination: additionalAgreementData.is_termination,
        meta: 'save_draft',
        number_addendum: additionalAgreementData.number_addendum ?? undefined,
        project_id: additionalAgreementData?.project,
        project_number: additionalAgreementData?.project_number,
        remote_territory_id: additionalAgreementData?.remote_territory,
        parent_signing_date: additionalAgreementData.parent_signing_date,
        signing_date: additionalAgreementData.signing_date,
        status: additionalAgreementData.status,
        sum: +additionalAgreementData.sum,
        sum_tax: calcVatSum(+additionalAgreementData.sum, vatRate),
        total_sum: calcTotalSum(+additionalAgreementData.sum, vatRate),
        tpi_id: additionalAgreementData?.tpi,
        works,
    };
}
