import {formatNumber} from './formatHelper';

export const calcApprovedCompletedWorksSum = (
    list: {
        approved_at: string | null;
        sum: string;
    }[],
): number =>
    list.reduce<number>((total, item) => (item.approved_at ? total + +item.sum : total), 0);

export const calcTotalCompletedWorksSum = (
    list: {
        approved_at: string | null;
        sum: string;
    }[],
): number => list.reduce<number>((total, item) => total + +item.sum, 0);

// калькуляция НДС
export function calcVatSum(sum: number, vatRate: number | null): number {
    return sum >= 0 ? formatNumber((sum * (vatRate ?? 0)) / 100) : 0;
}

// Калькуляция общей суммы
export function calcTotalSum(sum: number, vatRate: number | null): number {
    return sum >= 0 ? formatNumber(sum + calcVatSum(sum, vatRate)) : 0;
}
