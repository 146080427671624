import styled from 'styled-components';
import {Typography} from '@beeline/design-system-react';

export const CommentDate = styled.div`
    margin-top: 8px;
`;

export const CommentWrapper = styled.div`
    margin-top: 12px;
`;

export const StyledTypography = styled(Typography)`
    white-space: pre-line;
`;