import React from 'react';
import {sizeSpacingX4} from '@beeline/design-tokens/js/tokens';

import {TitleWithBackButton} from 'Features/TitleWithBackButton';
import {VatNotFoundBanner} from 'Entities/VatNotFoundBanner';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

interface VatErrorWidgetProps {
    errorText: string;
    onClose: () => void;
    hasLink?: boolean;
}

export const VatErrorWidget = ({errorText, onClose, hasLink}: VatErrorWidgetProps) => {
    return (
        <WrapperFlex maxWidth="880px" margin="0 auto" flexDirection="column" gap={sizeSpacingX4}>
            <TitleWithBackButton title={'Работа с документом невозможна'} onClick={onClose} />
            <VatNotFoundBanner errorText={errorText} onClose={onClose} hasLink={hasLink} />
        </WrapperFlex>
    );
};
