import React, {useEffect} from 'react';
import {Checkbox, Typography} from '@beeline/design-system-react';

import {Comment} from 'Entities/Comment';
import {DownloadableAttachment} from 'Features/DownloadableAttachment';
import {TrimTextArea} from 'Features/TrimTextArea';
import {IComment} from 'pages/DSPage/types';
import {useFileUploader} from 'shared/ui/use-file-uploader';
import {deriveFileList, prepareTFileList} from 'shared/helpers/transformObject';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {TFileUploaderMode} from 'shared/ui/use-file-uploader/useFileUploader.types';
import {EAttachmentType, TAttachmentFile} from 'shared/types/FileTypes';
import {DOCUMENT_ALLOWED_FORMAT_LIST, MAX_ALLOWED_UPLOAD_FILE_SIZE} from 'shared/const/fileFormats';
import {
    ADDITIONAL_AGREEMENT_SHORT_TITLE,
    AGREEMENT_COMMENTS_TITLE,
    ATTACHMENTS_TITLE,
    DOCUMENT_COMMENTS_TITLE,
    GET_PREPAYMENT_TITLE,
    OF_TITLE,
    PREPAYMENT_HINT,
    UNNECESSARY_TEXTAREA_LABEL,
} from '../../../shared/constants';

interface VirAttachmentAndCommentProps {
    id?: string;
    comment?: string;
    parentCommentList?: IComment[];
    loadingAttachmentId?: string;
    onChangeComment: (comment: string) => void;
    attachments: TAttachmentFile[];
    parentAttachments?: TAttachmentFile[];
    onChangeAttachments: (attachments: TAttachmentFile[]) => void;
    onDownloadParentAttachments?: (fileId: string, fileName: string, type: EAttachmentType) => void;
    isAllowToSetPrepayment?: boolean;
    is_prepayment?: boolean;
    onChangePrepayment?: (isPrepayment: boolean) => void;
}

export const ConstructorAttachmentUnit = ({
    id,
    comment,
    loadingAttachmentId,
    parentCommentList,
    onChangeComment,
    attachments,
    parentAttachments,
    onChangeAttachments,
    isAllowToSetPrepayment,
    is_prepayment,
    onChangePrepayment,
    onDownloadParentAttachments,
}: VirAttachmentAndCommentProps) => {
    const mode: TFileUploaderMode = id ? 'additionalAgreementCreation' : 'creation';

    const [FileUploader, files] = useFileUploader({
        fileUploaderName: 'Overall_add.agreement attachments',
        maxFileSize: MAX_ALLOWED_UPLOAD_FILE_SIZE,
        acceptFormats: DOCUMENT_ALLOWED_FORMAT_LIST,
        maxFiles: 10,
        mode,
        initialFiles: prepareTFileList(attachments),
    });

    useEffect(() => {
        const fileList = deriveFileList(files);
        onChangeAttachments(fileList);
    }, [files]);

    const handleOnChangePrepayment = () => {
        !!onChangePrepayment && onChangePrepayment(!is_prepayment);
    };

    return (
        <WrapperFlex flexWrap="wrap" gap="32px">
            <WrapperFlex flexDirection="column" flex="1 1 600px" minWidth="350px" gap="16px">
                <WrapperFlex flexDirection="column" gap="8px">
                    <Typography variant="h5">{ATTACHMENTS_TITLE}</Typography>
                    <FileUploader />
                </WrapperFlex>
                {isAllowToSetPrepayment && is_prepayment !== undefined && (
                    <WrapperFlex flexDirection="column" gap="8px">
                        <Typography variant="h5">{GET_PREPAYMENT_TITLE}</Typography>
                        <Typography variant="body2">{PREPAYMENT_HINT}</Typography>
                        <Checkbox
                            label={GET_PREPAYMENT_TITLE}
                            checked={is_prepayment}
                            disabled={!isAllowToSetPrepayment}
                            onChange={handleOnChangePrepayment}
                            name="prepayment"
                        />
                    </WrapperFlex>
                )}
                {!!parentAttachments?.length && (
                    <>
                        <Typography variant="h5">{`${ATTACHMENTS_TITLE} ${OF_TITLE} ${ADDITIONAL_AGREEMENT_SHORT_TITLE}`}</Typography>
                        {parentAttachments.map((item) => (
                            <DownloadableAttachment
                                id={item.id ?? ''}
                                key={item.id}
                                type={item.type}
                                title={item.file_name}
                                handleDownloadAttachment={onDownloadParentAttachments}
                                disabled={loadingAttachmentId === item.id}
                                isLoading={loadingAttachmentId === item.id}
                            />
                        ))}
                    </>
                )}
            </WrapperFlex>
            <WrapperFlex flexDirection="column" gap="8px" flex="1 1 440px">
                <Typography variant="h5">{DOCUMENT_COMMENTS_TITLE}</Typography>
                <TrimTextArea
                    value={comment}
                    onChange={onChangeComment}
                    label={UNNECESSARY_TEXTAREA_LABEL}
                    fullWidth
                />
                {!!parentCommentList?.length && (
                    <>
                        <Typography variant="h5">{AGREEMENT_COMMENTS_TITLE}</Typography>
                        {parentCommentList.map((item) => (
                            <Comment key={item.created_at} {...item} />
                        ))}
                    </>
                )}
            </WrapperFlex>
        </WrapperFlex>
    );
};
