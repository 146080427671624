import {max, min} from 'date-fns';

import {
    ConstructorVirType,
    CreateAdditionalAgreementRequestType,
    IUpdatedAgreement,
} from 'shared/types/additionalAgreementsTypes';
import {formatDate, formatNumber} from 'shared/helpers/formatHelper';

import {getAllDatesFromSpecs} from '../../helpers';

export function deleteEmptyVirs(
    works: Record<string, ConstructorVirType>,
): Record<string, ConstructorVirType> {
    const dsWorkObjects = works;
    for (let work in dsWorkObjects) {
        const ifEmptyWork = !dsWorkObjects?.[work]?.work_objects;
        if (ifEmptyWork) {
            delete dsWorkObjects[work];
        }
    }
    return dsWorkObjects;
}

export function transformAgreementToCreation(
    ds: IUpdatedAgreement,
    vatRate: number | null,
): CreateAdditionalAgreementRequestType {
    const filteredWorks = ds.works;
    const dates = getAllDatesFromSpecs(filteredWorks);
    const minStringDate = formatDate(min(dates), 'yyyy-MM-dd');

    const beginDate = minStringDate !== '1970-01-01' ? minStringDate : null;
    const endDate = dates.length ? formatDate(max(dates), 'yyyy-MM-dd') : '';
    const dsSum = Object.values(filteredWorks).reduce((acc, curr) => acc + curr.sum, 0);
    const dsSumTax = Object.values(filteredWorks).reduce((acc, curr) => acc + curr.sum_tax, 0);
    const dsTotalSum = Object.values(filteredWorks).reduce((acc, curr) => acc + curr.total_sum, 0);
    const sum = formatNumber(dsSum);
    const sum_tax = formatNumber(dsSumTax);
    const total_sum = formatNumber(dsTotalSum);

    return {
        is_prepayment: ds.is_prepayment,
        is_released: ds.is_released || false,
        agreement_id: ds.agreement_id || '',
        begin_date: beginDate,
        end_date: endDate,
        parent_signing_date: ds.parent_signing_date || undefined,
        project_id: ds.project_id || '',
        contract_id: ds.contract_id || '',
        tpi_id: ds.tpi_id || '',
        remote_territory_id: ds.remote_territory_id,
        sum,
        sum_tax,
        total_sum,
        works: Object.values(filteredWorks).map((el, idx) => {
            return {
                appendix_number: (idx + 1).toString(),
                sum: el.sum,
                sum_tax: el.sum_tax,
                total_sum: el.total_sum,
                work_objects: Object.values(el.work_objects)?.map((obj) => {
                    const result = {
                        work_type_id: obj.work_type_id,
                        object_type: obj.object_type,
                        transport_net_id: obj.transport_net_id,
                        is_half_set: obj.is_half_set,
                        lav_gfk: obj.lav_gfk,
                        work_specifications: Object.values(obj.work_specifications).map(
                            (spec, index) => ({
                                tpi_specification_id: spec.tpi_specification_id,
                                begin_date:
                                    spec.begin_date || ds.signing_date
                                        ? formatDate(
                                              ds.signing_date ? ds.signing_date : spec.begin_date,
                                              'yyyy-MM-dd',
                                          )
                                        : null,
                                end_date: spec.end_date
                                    ? formatDate(spec.end_date, 'yyyy-MM-dd')
                                    : null,
                                number: spec.number,
                                name: spec.name,
                                note: spec.note,
                                unit: spec.unit,
                                price: spec.price,
                                coefficient: spec.coefficient,
                                coefficient_ams: spec.coefficient_ams,
                                coefficient_foundation: spec.coefficient_foundation,
                                coefficient_remote_territory: spec.coefficient_remote_territory,
                                ordinal_number: index + 1,
                                quantity: spec.quantity,
                                is_category: spec.is_category,
                                is_agreed_price: spec.is_agreed_price,
                                is_ams: spec.is_ams,
                                is_foundation: spec.is_foundation,
                                is_prepayment: spec.is_prepayment,
                                master_position: spec.master_position,
                                rate: vatRate ?? 0,
                                sum: spec.sum,
                                sum_tax: spec.sum_tax,
                                total_sum: spec.total_sum,
                                work_specification_attachments:
                                    spec.work_specification_attachments || [],
                                work_coefficients: spec?.work_coefficients
                                    ? spec?.work_coefficients?.map((coefficient) => {
                                          return coefficient.is_manual
                                              ? {
                                                    is_manual: true,
                                                    coefficient: formatNumber(
                                                        coefficient.coefficient,
                                                    ),
                                                }
                                              : {
                                                    id: undefined,
                                                    // при создании ДС используется id, при создании дополнения - tpi_specification_id
                                                    tpi_specification_id:
                                                        coefficient.tpi_specification ??
                                                        coefficient.id,
                                                    number: coefficient.number,
                                                    name: coefficient.name,
                                                    ...(coefficient.note
                                                        ? {note: coefficient.note}
                                                        : {}),
                                                    coefficient: formatNumber(
                                                        coefficient.coefficient,
                                                    ),
                                                };
                                      })
                                    : [],
                                work_specification_comments:
                                    spec.work_specification_comments &&
                                    Object.keys(spec.work_specification_comments).length
                                        ? Object.values(spec.work_specification_comments)
                                        : [],
                                work_category_id: spec.work_category_id,
                                category_name: spec.category_name,
                            }),
                        ),
                    };

                    return result;
                }),
            };
        }),
        agreement_comments: Object.values(ds?.agreement_comments || {}),
        agreement_attachments: ds.agreement_attachments,
        meta: ds.meta,
    };
}
