import {set} from 'lodash';
import {combine, sample} from 'effector';

import {AddedWorkObject} from 'pages/AddWorkObjects/types';
import {AddWorkObjectGate} from 'pages/AddWorkObjects/model';
import {$vatRate} from 'shared/model/user';

import {
    $createAdditionalAgreementData,
    $virs,
    addWorkObjectsListToVirs,
    deleteWorkObject,
    deleteWorkSpecification,
    duplicateWorkSpecification,
    updateSpecCoefficient,
    updateSpecPrice,
    updateSpecQuantity,
} from './stores';
import {tpisFullInfoLoaded} from './init';
import {calculateVirTotals, extractWorkObjects, getPathToVir, getVir} from '../helpers';

sample({
    clock: AddWorkObjectGate.open,
    source: $createAdditionalAgreementData,
    fn: (ds) => Object.keys(ds.works).map((el, idx) => ({id: el, name: `Ведомость ${idx + 1}`})),
    target: $virs,
});

const calculateAfterUpdateSpec = sample({
    clock: [
        updateSpecQuantity,
        updateSpecPrice,
        updateSpecCoefficient,
        deleteWorkSpecification,
        duplicateWorkSpecification,
        deleteWorkObject,
        tpisFullInfoLoaded,
    ],
    filter: ({virId}) => !!virId,
    fn: ({virId}) => virId,
});

sample({
    clock: calculateAfterUpdateSpec,
    source: combine({
        data: $createAdditionalAgreementData,
        vatRate: $vatRate,
    }),
    fn: ({data, vatRate}, virId) => {
        const ds = {...data};
        set(ds, getPathToVir({virId}), {
            ...getVir({ds, virId}),
            ...calculateVirTotals({ds, virId}, vatRate),
        });
        return ds;
    },
    target: $createAdditionalAgreementData,
});

sample({
    clock: addWorkObjectsListToVirs,
    source: combine({
        ds: $createAdditionalAgreementData,
        vatRate: $vatRate,
    }),
    fn: ({ds, vatRate}, list) => {
        const filtered = list.reduce<AddedWorkObject[]>((acc, curr) => {
            const allowedToAdd =
                curr.workObject &&
                curr.workType &&
                curr.statement &&
                !acc.find((el) => el.workObject?.label === curr?.workObject?.label);
            return allowedToAdd ? [...acc, curr] : acc;
        }, []);
        const virsToAdd = filtered.reduce((acc, curr) => {
            const workObjects = ds.works?.[`${curr.statement.virId}`]?.work_objects;
            const existingObjectNames = workObjects
                ? Object.values(workObjects).reduce<string[]>(
                      (acc, curr) => [...acc, curr.object_name],
                      [],
                  )
                : [];
            const lavParts = curr.workObject?.lavParts;
            const isLav = !!lavParts;
            const filterByLavPartsWorkObject = {
                ...curr.workObject,
                ...(isLav
                    ? {
                          lavParts: curr.workObject.lavParts?.filter(
                              (el) => !existingObjectNames.includes(el.object_name),
                          ),
                      }
                    : {}),
            };

            if (isLav) {
                lavParts.map((item) => item);
                const workObjectList = ds.works?.[`${curr.statement.virId}`]?.work_objects;
                for (let obj in workObjectList) {
                    if (workObjectList[obj].is_half_set !== undefined) {
                        workObjectList[obj].is_half_set = false;
                        workObjectList[obj].lav_gfk = lavParts[0]?.lav_gfk ?? undefined;
                    }
                }
            }

            return {
                ...acc,
                [`${curr.statement.virId}`]: {
                    ...ds.works?.[`${curr.statement.virId}`],
                    id: curr.statement.virId,
                    work_objects: {
                        ...ds.works?.[`${curr.statement.virId}`]?.work_objects,
                        ...acc?.[`${curr.statement.virId}`]?.work_objects,
                        ...extractWorkObjects({
                            virId: curr.statement.virId || '',
                            workObject: filterByLavPartsWorkObject,
                            workType: curr.workType,
                            specs: [],
                            vatRate,
                        }),
                    },
                },
            };
        }, {});
        return {...ds, works: {...ds.works, ...virsToAdd}};
    },
    target: $createAdditionalAgreementData,
});
