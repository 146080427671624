import cn from 'classnames';
import {forwardRef, useReducer} from 'react';

import {EFileUploadStatus, EMPTY_DATA_OBJ, EXTERNAL_ERROR_TEXT} from './constants';

import {FileList as DefaultFileList, FileUploaderText} from './components';
import {useDragAndDropControls} from './hooks';
import {fileUploaderReducer} from './store';
import styles from './useFileUploader.module.css';
import type {IFileUploaderHook, TFileDataList} from './useFileUploader.types';
import {Typography} from '@beeline/design-system-react';
import {FILE_ERROR_BASE, FILE_ERROR_MESSAGES} from 'shared/ui/use-file-uploader/store/constants';

export const useFileUploader: IFileUploaderHook = (config) => {
    const {
        helperText,
        acceptFormats,
        mainText,
        uploadActionText,
        CustomFileList,
        fileUploaderName,
        minFiles,
        maxFiles,
        maxFileSize,
        initialFiles = [],
        isFilesDeleteAvailable = true,
        mode,
        externalError = false,
        withRegistrationDate = false,
        formTouched = false,
    } = config;

    const [{files, error}, fileUploaderDispatch] = useReducer(fileUploaderReducer, {
        files: initialFiles.reduce<TFileDataList>(
            (prevValue, currFile) => ({
                ...prevValue,
                [currFile.name]: currFile,
            }),
            EMPTY_DATA_OBJ,
        ),
        error: undefined,
    });
    const reactiveExternalError =
        externalError && !Object.values(files).some((value) => value.status === 'upload');

    const filesList = Object.values(files);
    const maxAllowedFiles = maxFiles ? maxFiles - filesList.length : null;
    const registrationDateError =
        formTouched &&
        withRegistrationDate &&
        filesList.some((item) => !item.registration_at && item.status === EFileUploadStatus.Upload);
    const minFileCountError = !!minFiles && formTouched ? filesList.length < minFiles : false;
    const {
        onDragLeaveHandler,
        onDragOverHandler,
        onDropFileHandler,
        onChangeFileHandler,
        onChangeRegistrationDateHandler,
        isDragging,
    } = useDragAndDropControls({
        acceptFormats,
        uploadAvailableFilesCount: maxAllowedFiles,
        maxFileSize,
        fileUploaderDispatch,
        error,
        registrationDateError,
        minFileCountError,
    });

    const FileList = CustomFileList || DefaultFileList;
    const hasHelperText = !!helperText;
    const hasError = !!error;
    const isError =
        !!error &&
        error !== `${FILE_ERROR_BASE}${FILE_ERROR_MESSAGES.maxFileSize}` &&
        error !== `${FILE_ERROR_BASE}${FILE_ERROR_MESSAGES.allowedFormats}`;
    const FileUploader = forwardRef<HTMLInputElement>(
        (_, ref): JSX.Element => (
            <div
                className={cn(styles.container, {
                    [styles.editingContainer]: mode === 'editing',
                })}
            >
                <div className={styles.uploader__container}>
                    <div
                        onDragLeave={onDragLeaveHandler}
                        onDragOver={onDragOverHandler}
                        onDrop={onDropFileHandler}
                        className={cn({
                            [styles.uploader]: mode !== 'editing',
                            [styles.editingUploader]: mode === 'editing',
                            [styles.uploader__dragging]: isDragging,
                            [styles.uploader__error]: hasError || reactiveExternalError,
                        })}
                    >
                        <FileUploaderText
                            ref={ref}
                            isDragging={isDragging}
                            mainText={mainText}
                            uploadActionText={uploadActionText}
                            onDropFileHandler={onDropFileHandler}
                            fileUploaderName={fileUploaderName}
                            acceptFormats={acceptFormats}
                            maxFileSize={maxFileSize}
                            minFiles={minFiles}
                            maxFiles={maxFiles}
                            mode={mode}
                            externalError={reactiveExternalError}
                        />
                    </div>
                    {(hasHelperText || hasError || reactiveExternalError) && (
                        <div className={styles.uploader__helper_text}>
                            <Typography
                                variant="caption"
                                className={cn({
                                    [styles.uploader__helper_text__error]:
                                        hasError || reactiveExternalError,
                                })}
                            >
                                {reactiveExternalError ? EXTERNAL_ERROR_TEXT : error || helperText}
                            </Typography>
                        </div>
                    )}
                </div>
                <FileList
                    isFilesDeleteAvailable={isFilesDeleteAvailable}
                    fileUploaderDispatch={fileUploaderDispatch}
                    onChangeFile={onChangeFileHandler}
                    onChangeRegistrationDate={onChangeRegistrationDateHandler}
                    files={filesList}
                    mode={mode}
                    withRegistrationDate={withRegistrationDate}
                    formTouched={formTouched}
                />
            </div>
        ),
    );

    FileUploader.displayName = 'FileUploader';

    return [FileUploader, files, fileUploaderDispatch, isError];
};
